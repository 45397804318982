import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import { Modal, Select, message } from "antd";
import { toast, Toaster } from "react-hot-toast";
import { IoIosAddCircleOutline } from "react-icons/io";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import * as Bs from "react-icons/bs";

function GenerateInvoice() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    generateInvoice,
    balanceDue,
    setBalanceDue,
    invoiceNumber,
    setInvoiceNumber,
    companiesList,
    singleInvoiceGeneration,
  } = useContext(AppContext);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  // const invoiceData = JSON.parse(sessionStorage.getItem("DirectProposal"));
  const enquiry = JSON.parse(sessionStorage.getItem("enquiryData"));
  const seletedPro = sessionStorage.getItem("selectedProduct");
  const [packageDetails, setPackageDetails] = useState(null);
  const product = JSON.parse(seletedPro);
  const [servicesTotal, setServicesTotal] = useState(0);
  const [itemsTotal, setItemsTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isExport, setIsExport] = useState(false);
  const [vatAmount, setVatAmount] = useState(0);

  useEffect(() => {
    if (seletedPro != null) {
      const pack = JSON.parse(seletedPro);
      setPackageDetails(JSON.parse(pack?.productDetails));
    }
  }, []);

  const [editInvoice, setEditInvoice] = useState({
    username: enquiry?.sellerDetails?.sellerName,
    email: enquiry?.sellerDetails?.sellerEmail,
    billingAddress:
      enquiry?.sellerDetails?.address1 +
      " " +
      enquiry?.sellerDetails?.address2 +
      " " +
      enquiry?.sellerDetails?.town +
      " " +
      enquiry?.sellerDetails?.postcode +
      " " +
      enquiry?.sellerDetails?.country,
    dueDate: null,
    paymentAccount: "123456",
    branch: "Trivandrum",
    method: "other",
    bank: "State Bank of India ",
    ifsc: "SBI123456",
    price: product?.price,
    sortCode: "SORTCODE",
    swifCode: "SWIFTCODE",
    discount: 0,
    deposit: 0,
    message: "",
    productDescription: "",
  });
  console.log(packageDetails, "package");

  useEffect(() => {
    if (editInvoice?.discount == 0) {
      setVatAmount(packageDetails?.values?.VATAmount ?? 0);
    } else {
      const vatamount =
        ((packageDetails?.values?.allTotal - editInvoice?.discount) *
          packageDetails?.values?.VAT) /
        100;
      setVatAmount(vatamount);
    }
  }, [editInvoice]);

  useEffect(() => {
    if (packageDetails) {
      const beforeAdvance =
        packageDetails?.values?.allTotal + vatAmount - editInvoice.discount;

      setTotalAmount(beforeAdvance);

      setBalanceDue(beforeAdvance);
      const after =
        ((packageDetails?.values?.allTotal - editInvoice?.discount) *
          packageDetails?.values?.VAT) /
          100 +
        packageDetails?.values?.allTotal -
        editInvoice?.discount -
        editInvoice.deposit;

      setBalanceDue(after);
    }
  }, [editInvoice, packageDetails, vatAmount]);
  const handleMethod = (e) => {
    setEditInvoice((prevEditDeals) => ({
      ...prevEditDeals,
      method: e.target.value,
    }));
  };
  const handleDiscount = (e) => {
    setEditInvoice((prev) => ({
      ...prev,
      discount: e.target.value,
    }));
  };
  const handleOk = () => {
    setIsExport(false);
  };

  const handleCancel = () => {
    setIsExport(false);
  };

  const handleDepositChange = (e) => {
    setEditInvoice((prev) => ({
      ...prev,
      deposit: e.target.value,
    }));
  };
  // const handleSub = (e) => {
  //   setEditInvoice((prevEditDeals) => ({
  //     ...prevEditDeals,
  //     subscription: e.target.value,
  //   }));
  // };
  useEffect(() => {
    setEditInvoice((prev) => ({
      ...prev,
      invoiceDate: getCurrentDate(),
    }));
  }, []);

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setEditInvoice((prev) => {
      const isDateInput = e.target.type === "date";
      const formattedValue = isDateInput ? e.target.value : value;
      return { ...prev, [name]: formattedValue };
    });
  };
  const handlePriceChange = (e) => {
    setEditInvoice((prev) => ({
      ...prev,
      price: e.target.value,
    }));
  };
  const onChnageInfo = (e) => {
    const { name, value } = e.target;
    setEditInvoice((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // const onChangeDescription = (e) => {
  //   const { name, value } = e.target;
  //   setEditInvoice((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  const getCurrentDate = () => {
    const currentDate = new Date();

    const formattedDate = currentDate.toISOString().split("T")[0];
    return formattedDate;
  };
  const contractDetails = JSON.parse(
    sessionStorage.getItem("selectedContract")
  );

  useEffect(() => {
    if (!packageDetails) return;

    let totalValue = 0;
    if (Array.isArray(packageDetails.services)) {
      packageDetails.services.forEach((service) => {
        if (Array.isArray(service.items)) {
          service.items.forEach((item) => {
            const itemValue = parseFloat(item.value) || 0;
            totalValue += itemValue;
          });
        }
      });
    }

    let itemsTotalVal = 0;
    let newValue = 0;
    if (Array.isArray(packageDetails.itemsArray)) {
      packageDetails.itemsArray.forEach((item) => {
        const itemPrice = parseFloat(item.price) || 0;
        itemsTotalVal += itemPrice;
      });
    }
    if (Array.isArray(packageDetails.elementArray)) {
      packageDetails.elementArray.forEach((item) => {
        const itemPrice = parseFloat(item.price) || 0;
        newValue += itemPrice;
      });
    }
  
    setItemsTotal(parseFloat(itemsTotalVal) + parseFloat(newValue));
    setServicesTotal(totalValue);
  }, [packageDetails]);
  console.log(itemsTotal, "itemstotal");
  const validate = () => {
    if (
      editInvoice.username == undefined ||
      editInvoice.email == undefined ||
      editInvoice.billingAddress == undefined ||
      editInvoice.method.length === 0
    ) {
      toast.error("Please Fill all Fields!");
      return;
    }
    // if (
    //   !product ||
    //   !product?.productName ||
    //   !product?.price ||
    //   !product?.tax ||
    //   !product?.quantity
    // ) {
    //   toast.error(
    //     "Product details are not available. Please try again by generating the invoice!"
    //   );
    //   navigate(-1);
    //   return;
    // }
  };

  const submitInvoice = async () => {
    if (editInvoice.dueDate == null) {
      toast.error("Please Select Duedate!");
      return;
    }
    validate();
    const ldId = sessionStorage.getItem("leadId");
    const leadId = ldId ?? "";

    const invoiceData = JSON.parse(seletedPro);
    const productData = [
      {
        productName: packageDetails?.packageName,
        price: packageDetails?.values?.subTotal,
        tax: packageDetails?.values?.VAT,
        taxAmount: vatAmount,
        discount: editInvoice?.discount,
        totalBeforeDeposit: totalAmount,
        deposit: editInvoice?.deposit,
        services: packageDetails?.services,
        packageDescription: packageDetails?.packageDescription,
        showZerovalue: packageDetails?.showZerovalue,
      },
    ];
    const bankdata = {
      paymentAccount: editInvoice.paymentAccount,
      bank: editInvoice.bank,
      ifsc: editInvoice.ifsc,
      sortCode: editInvoice.sortCode,
      swifCode: editInvoice.swifCode,
      branch: editInvoice.branch,
    };
    const payload = {
      propId: invoiceData.propId,
      total: balanceDue,
      cust_name: editInvoice.username,
      cust_email: editInvoice.email,
      billingAddress: editInvoice.billingAddress,
      invoice_date: editInvoice.invoiceDate,
      due_date: editInvoice.dueDate,
      method: editInvoice.method,
      bankDetails: JSON.stringify(bankdata),
      productDetails: JSON.stringify(productData),
      deposit: editInvoice?.deposit,
      message: editInvoice.message,
      leadId: leadId && typeof leadId == "string" ? parseInt(leadId) : leadId,
    };
    const storeData = {
      payload,
      message: editInvoice.message,
      productDescription: editInvoice.productDescription,
      bankDetails: editInvoice,
      packageDetails,
    };
    sessionStorage.setItem("invoiceDetails", JSON.stringify(storeData));

    try {
      setInvoiceLoading(true);
      await singleInvoiceGeneration(payload, {
        onSuccess: async (res) => {
          setInvoiceLoading(false);
          sessionStorage.setItem(
            "DirectInvoiceNumber",
            JSON.stringify(res.data)
          );
          const Invoice = JSON.parse(
            sessionStorage.getItem("DirectInvoiceNumber")
          );
          navigate(`/ferns-invoice/${Invoice != null && Invoice?.invoiceId}`);
          setTimeout(() => {
            setEditInvoice({
              username: "",
              email: "",
              billingAddress: "",
              dueDate: null,
              discount: 0,
              deposit: 0,
              message: "",
              productDescription: "",
            });
          }, [1000]);
        },
        onFailed: (err) => {
          toast.error(err.message);
          setInvoiceLoading(false);
        },
      });
    } finally {
      setInvoiceLoading(false);
    }
  };
  const Nodata = () => toast("product Details are Empty");

  return (
    <div className="container-fluid">
      <Toaster position="top-center" reverseOrder={false} />
      <Sidebar>
        <section id="content-wrapper" class="dashboard-content-wrapper">
          <div className="row">
            <div className="col-md-10">
              <h6 className="gen_in">Generate Invoice</h6>
            </div>
            <div className="col-md-2">
              {invoiceLoading ? (
                <button className="gen_btn" type="button" disabled>
                  Generating...
                </button>
              ) : (
                <button
                  className="gen_btn"
                  type="button"
                  onClick={() => submitInvoice()}
                >
                  Generate
                </button>
              )}
            </div>
          </div>

          <Modal
            open={isExport}
            onOk={handleOk}
            width={"20%"}
            footer={false}
            onCancel={handleCancel}
          >
            {invoiceLoading ? (
              <h6>Generating....</h6>
            ) : (
              <>
                <p className="mt-3 mb-3">
                  Generate Invoice
                  <button
                    className="exis_btn"
                    onClick={() => {
                      sessionStorage.setItem("isExport", "false");
                      submitInvoice();
                    }}
                  >
                    <Bs.BsArrowRight className="animated-arrow" />
                  </button>
                </p>
              </>
            )}
          </Modal>

          <div className="row">
            <div className="col in_data ">
              <small> Customer Name</small> <br />
              <div className="mt-1">
                <input
                  type="text"
                  className="p-1 invoice_input"
                  value={editInvoice.username}
                  name="username"
                  onChange={onChnageInfo}
                />
              </div>
            </div>
            <div className="col in_data custo_name">
              <small> Email</small> <br />
              <div className="mt-1">
                <input
                  type="email"
                  className="p-1 invoice_input"
                  value={editInvoice.email}
                  onChange={onChnageInfo}
                  name="email"
                />
              </div>
            </div>
            <div className="col in_data">
              <small> Payment Method</small> <br />
              <select
                name="method"
                className="form-control"
                value={editInvoice?.method}
                onChange={handleMethod}
              >
                <option value="stripe">Stripe</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <small className="">Customer Address</small>
              <br />
              <textarea
                type="text"
                className="p-1 invoice_teactarea"
                value={editInvoice.billingAddress}
                onChange={onChnageInfo}
                name="billingAddress"
              />
            </div>
            <div className="col-md-2 col-sm-6">
              <small>Invoice Date</small>
              <br />
              <input
                id="invoiceDate"
                className="form-control"
                type="date"
                value={editInvoice.invoiceDate}
                onChange={handleChangeData}
                name="invoiceDate"
              />
            </div>
            <div className="col-md-2 col-sm-6">
              <small>Due Date</small>
              <br />
              <input
                type="date"
                className="inv_dat"
                value={editInvoice.dueDate}
                name="dueDate"
                onChange={onChnageInfo}
                min={new Date().toISOString().split("T")[0]}
              />
            </div>
          </div>

          <div className="card mt-3 p-3">
            <div className="row ">
              <h6 className="sub_title">Invoice Details</h6>
              <div className="container">
                <p className="pack_name">
                  {packageDetails?.packageName ?? "N/A"} -{" "}
                  {packageDetails && packageDetails?.services
                    ? packageDetails?.services.length
                    : 0}{" "}
                  course menu <br />
                  <small style={{ fontWeight: "500", fontSize: "10px" }}>
                    {packageDetails?.packageDescription ?? ""}
                  </small>
                </p>

                <table
                  className="table table-bordered table-light table-striped-columns table-hover"
                  // id="fp-table"
                >
                  <thead>
                    <th>Course</th>
                    <th>Total</th>
                  </thead>
                  <tbody>
                    {packageDetails &&
                      packageDetails?.services &&
                      Array.isArray(packageDetails?.services) &&
                      packageDetails?.services.length !== 0 &&
                      packageDetails?.services.map((item, index) => {
                        // Calculate the total value of the food list
                        const foodList = item?.items;
                        const totalValue =
                          foodList &&
                          foodList.reduce((acc, foodItem) => {
                            const itemValue =
                              typeof foodItem.value === "string"
                                ? parseFloat(foodItem.value) || 0
                                : typeof foodItem.value === "number"
                                ? foodItem.value
                                : 0;
                            return acc + itemValue;
                          }, 0);

                        // Check if we should display this course
                        const shouldDisplayCourse =
                          packageDetails?.showZerovalue || totalValue > 0;

                        if (!shouldDisplayCourse) return null; // Skip if course shouldn't be displayed

                        return (
                          <tr className="position-relative" key={index}>
                            <td style={{ width: "82%" }}>
                              <div className="position-relative">
                                <p className="mt-2 mb-2 ps-2 text-capitalize">
                                  {item.courseHead ?? "N/A"} -{" "}
                                  <small>{item?.description ?? ""}</small>
                                </p>
                                <ul className="pb-1 pt-0 m-0">
                                  {foodList &&
                                    foodList.length !== 0 &&
                                    foodList.map((x, idx) => {
                                      const itemValue =
                                        typeof x.value === "string"
                                          ? parseFloat(x.value) || 0
                                          : typeof x.value === "number"
                                          ? x.value
                                          : 0;

                                      // Only show item if showZerovalue is true or itemValue is not 0
                                      if (
                                        packageDetails?.showZerovalue ||
                                        itemValue > 0
                                      ) {
                                        return (
                                          <li
                                            style={{
                                              color: "#747474",
                                              paddingTop: "5px",
                                            }}
                                            key={idx}
                                          >
                                            {x.head ?? "N/A"} - £{x.value ?? 0}
                                          </li>
                                        );
                                      }
                                      return null;
                                    })}
                                </ul>
                              </div>
                            </td>
                            <td>
                              <span className="text_btm_">
                                £ {totalValue ?? 0}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>

                  <tfoot>
                    <tr>
                      <td></td>
                      <td className="text-start">£ {servicesTotal ?? 0}</td>
                    </tr>
                  </tfoot>
                </table>
                <p className="pack_name mb-2">Extra Charges</p>
                <table className="table table-bordered table-light table-striped-columns table-hover">
                  <thead>
                    <th scope="col">Charges</th>
                    <th scope="col">Price</th>
                  </thead>
                  <tbody>
                    {packageDetails &&
                      packageDetails?.itemsArray &&
                      Array.isArray(packageDetails?.itemsArray) &&
                      packageDetails?.itemsArray.length !== 0 &&
                      packageDetails?.itemsArray.map((item, index) => {
                        const itemPrice =
                          typeof item.price === "string"
                            ? parseFloat(item.price) || 0
                            : typeof item.price === "number"
                            ? item.price
                            : 0;

                        if (
                          packageDetails?.showZerovalue ||
                          itemPrice !== 0 ||
                          itemPrice !== "0"
                        ) {
                          return (
                            <tr key={index}>
                              <td>{item.description ?? "N/A"}</td>
                              <td>£ {itemPrice ?? 0}</td>
                            </tr>
                          );
                        }
                        return null;
                      })}
                    {packageDetails &&
                      packageDetails?.elementArray &&
                      Array.isArray(packageDetails?.elementArray) &&
                      packageDetails?.elementArray.length !== 0 &&
                      packageDetails?.elementArray.map((item, index) => {
                        const itemPrice =
                          typeof item?.price === "string"
                            ? parseFloat(item?.price) || 0
                            : typeof item.price === "number"
                            ? item?.price
                            : 0;

                        if (
                          packageDetails?.showZerovalue ||
                          itemPrice !== 0 ||
                          itemPrice !== "0"
                        ) {
                          return (
                            <tr key={index}>
                              <td>{item?.item ?? "N/A"}</td>
                              <td>£ {item.price ?? 0}</td>
                            </tr>
                          );
                        }
                        return null;
                      })}
                    <tr>
                      <td>
                        <b>Total of Extra Charges</b>
                      </td>
                      <td>
                        <b>{"£" + itemsTotal ?? 0}</b>
                      </td>
                    </tr>
                  </tbody>

                  <tfoot>
                    <tr
                      class="text-offset"
                      style={{ border: "none !important" }}
                    >
                      <td style={{ fontWeight: "700", color: "#395870" }}>
                        Sub Total
                      </td>
                      <td className="text-left">
                        <strong>
                          {" "}
                          £ {packageDetails?.values?.allTotal ?? 0}
                        </strong>
                      </td>
                    </tr>
                    <tr class="text-offset table-row">
                      <td>Discount amount</td>
                      <td className="text-left">
                        {" "}
                        <input
                          type="text"
                          className="discount"
                          value={editInvoice.discount}
                          onChange={handleDiscount}
                          name="discount"
                        />
                      </td>
                    </tr>
                    <tr
                      class="text-offset"
                      style={{ border: "none !important" }}
                    >
                      <td>VAT Amount ({packageDetails?.values?.VAT + "%"})</td>
                      <td className="text-left">£ {vatAmount}</td>
                    </tr>
                    <tr class="text-offset table-row">
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td className="text-left">
                        <strong>£ {totalAmount ?? 0}</strong>
                      </td>
                    </tr>
                    <tr class="text-offset table-row">
                      <td className="table-row mt-3">Deposit</td>
                      <td className="text-left table-row">
                        <input
                          type="text"
                          className="discount"
                          value={editInvoice.deposit}
                          onChange={handleDepositChange}
                          name="deposit"
                        />
                      </td>
                    </tr>{" "}
                    <tr class="text-offset">
                      <td>
                        <b>Balance Due</b>
                      </td>
                      <td className="text-left">£ {balanceDue ?? "N/A"}</td>
                    </tr>
                  </tfoot>
                </table>
                {/* <label>Package Description</label>
                <textarea
                  cols={10}
                  rows={3}
                  type="text"
                  className="p-2 mt-1 invoice_input"
                  value={editInvoice.productDescription}
                  name="productDescription"
                  maxLength="70"
                  onChange={onChnageInfo}
                /> */}
              </div>
            </div>
          </div>
          <div className="row mt-3">
            {/* <div className="col-md-6 col-sm-6 col-xl-4">
              <div className="card p-3">
                <div className="mt-3">
                  <b> Message on Invoice</b> <hr />
                </div>
                <p>
                  {" "}
                  {editInvoice?.bank} a/c :{editInvoice?.paymentAccount}
                </p>
                {editInvoice?.ifsc != null && editInvoice?.ifsc.length != 0 && (
                  <p>IFSC : {editInvoice?.ifsc ?? "N/A"}</p>
                )}

                <p>Branch : {editInvoice?.branch}</p>

                {editInvoice?.sortCode != null &&
                  editInvoice?.sortCode != undefined &&
                  editInvoice?.sortCode != "" && (
                    <p> SortCode : {" " + editInvoice?.sortCode ?? "N/A"}</p>
                  )}
                {editInvoice?.swifCode != null &&
                  editInvoice?.swifCode != undefined &&
                  editInvoice?.swifCode != "" && (
                    <p> SwtftCode : {" " + editInvoice?.swifCode ?? "N/A"}</p>
                  )}
              </div>
            </div>
            <div className="col-md-4"></div> */}
            <div className="col-md-4">
              <div className="col in_data">
                <label htmlFor="">Message</label>
                <div className="mt-1">
                  <textarea
                    className="message-inv p-2"
                    value={editInvoice.message}
                    name="message"
                    onChange={onChnageInfo}
                  />
                </div>
              </div>
              {/* <div className="col in_data">
                <label htmlFor="">Attach file</label>
                <div className="mt-1">
                
                  <input type="file" className="attach-inv"></input>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </Sidebar>
    </div>
  );
}

export default GenerateInvoice;
