import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Sidebar from "../../Components/Sidebar";
import { AppContext } from "../../Context/AppContext";
import Utils from "../../Utils/utils";
import * as Md from "react-icons/md";
import * as Ai from "react-icons/ai";
import * as Bs from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Popconfirm } from "antd";

function Drafts() {
  const buttonRef = useRef(null);
  const navigate = useNavigate();
  const { fetchAllDrafts, draftsList, draftLoading, deleteProposalDraft } =
    useContext(AppContext);
  const [toggleDropdown, setToggleDropdown] = useState(-1);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setToggleDropdown(-1); // Set state when clicked outside
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleNavigate = (proposal) => {
    sessionStorage.setItem("draftProposal", JSON.stringify(proposal));
    if (proposal?.pceId && proposal?.leadId) {
      navigate(
        `/add-new-propsal?draft&&pceId=${proposal?.pceId}&&leadId=${proposal?.leadId}`
      );
      return;
    }
    navigate("/add-new-propsal?isSingle&&draft");
  };

  const deleteDraftData = async (id) => {
    try {
      setDeleteLoading(true);
      await deleteProposalDraft(id, {
        onSuccess: (res) => {
          toast.success("Draft has been removed successfully!");
          fetchAllDrafts();
          setToggleDropdown(-1);
        },
        onFailed: (err) => {
          console.log(err);
          toast.error("Draft not removed");
        },
      });
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <Fragment>
      <Sidebar>
        <h6 className="head-h4 ps-3">Proposal's Draft</h6>
        <p className="proposal_information">
          <i>
            <Bs.BsDiagram3Fill />
          </i>{" "}
          mentioned as the proposal created from Customers.
        </p>
        <div className="card p-3 m-3 overflow">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Client Name</th>
                <th scope="col">email</th>
                <th scope="col">Updated at</th>
                <th scope="col">Created at</th>
                <th scope="col" className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {draftsList &&
                draftsList.length != 0 &&
                draftsList.map((data, index) => {
                  const id = data?.prodraftd;
                
                  return (
                    <Fragment>
                      <tr>
                        <td>{index + 1}</td>
                        <td className="position-relative">
                          {data?.name ?? "N/A"}{" "}
                          {data?.leadId != null && data?.pceId != null && (
                            <div
                              className="proposal_lead_badge"
                              title="Created from Lead"
                            >
                              <Bs.BsDiagram3Fill />
                            </div>
                          )}
                        </td>
                        <td>{data?.email ?? "N/A"}</td>
                        <td>
                          {data?.updated_at
                            ? Utils.formatDate(data?.updated_at)
                            : "N/A"}
                        </td>
                        <td>
                          {data?.created_at
                            ? Utils.formatDate(data?.created_at)
                            : "N/A"}
                        </td>
                        <td className="position-relative">
                          <div className="btn-toggle">
                            <button
                              className="one-btn"
                              style={{ minWidth: "90px" }}
                              onClick={() => handleNavigate(data)}
                              disabled={deleteLoading}
                            >
                              Continue
                            </button>
                            <button
                              className="two-btn"
                              onClick={() => setToggleDropdown(index)}
                            >
                              <Md.MdOutlineArrowDropDown />
                            </button>
                          </div>
                          {toggleDropdown === index && (
                            <ul
                              className="table-mgt"
                              role="menu"
                              // ref={buttonRef}
                            >
                              <li>
                                <Popconfirm
                                  title="  Delete Draft"
                                  description="Are you sure to Delete this draft?"
                                  onConfirm={() => deleteDraftData(id)}
                                  onCancel={() => setToggleDropdown(-1)}
                                  okText="Yes"
                                  cancelText="No"
                                  okType="danger"
                                  icon={
                                    <Ai.AiOutlineQuestionCircle
                                      style={{
                                        color: "red",
                                        marginRight: "10px",
                                      }}
                                    />
                                  }
                                >
                                  <button
                                    type="button"
                                    className="btn"
                                    disabled={deleteLoading}
                                    style={{
                                      background: "transparent",
                                      border: "none",
                                      outline: "none",
                                      width: "100%",
                                    }}
                                  >
                                    Delete
                                  </button>
                                </Popconfirm>
                              </li>
                            </ul>
                          )}
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Sidebar>
    </Fragment>
  );
}

export default Drafts;
