import { useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";
import BaseClient from "../Helpers/BaseClient";

const useProposal = () => {
  const [templates, setTemplates] = useState(null);
  const [proposalLoading, setProposalLoading] = useState(false);
  const [allProposals, setAllProposals] = useState(null);
  const [isProposalDataLoading, setIsProposalDataLoading] = useState(false);
  const [proposalInvoices, setProposalInvoices] = useState(null);
  const [proposalContracts, setProposalContracts] = useState(null);
  const [mailTemplates, setMailTemplates] = useState(null);
  const [paymentHistory, setPaymentHistory] = useState(null);
  const [mailLoading, setMailLoading] = useState(false);
  const [proposalsList, setProposalsList] = useState(null);
  const [draftLoading, setDraftLoading] = useState(false);
  const [draftsList, setDraftsList] = useState(null);

  const getAllTemplates = async () => {
    try {
      setProposalLoading(true);
      await BaseClient.get(APIEndpoints.getAllProposalTemplates, {
        onSuccess: (res) => {
          setTemplates(res.data);
        },
        onFailed: (err) => {
          console.log(err);
        },
      });
    } finally {
      setProposalLoading(false);
    }
  };
  const getAllProposals = async (formData, { onSuccess, onFailed }) => {
    try {
      setProposalLoading(true);
      await BaseClient.post(APIEndpoints.getProposal, formData, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setProposalLoading(false);
    }
  };
  const fetchProposalByleadId = async (payload, { onSuccess, onFailed }) => {
    try {
      setProposalLoading(true);
      await BaseClient.post(APIEndpoints.getProposalByLeadId, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setProposalLoading(false);
    }
  };
  const getNewFinalizedProposals = async (payload, { onSuccess, onFailed }) => {
    try {
      setProposalLoading(true);
      await BaseClient.post(APIEndpoints.getFinalizedProposalNew, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setProposalLoading(false);
    }
  };
  const proposalSending = async (formData, { onSuccess, onFailed }) => {
    try {
      setProposalLoading(true);
      await BaseClient.post(APIEndpoints.sendPropsal, formData, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setProposalLoading(false);
    }
  };

  const createSingleProposal = async (formData, { onSuccess, onFailed }) => {
    try {
      setProposalLoading(true);
      await BaseClient.post(APIEndpoints.createSingleProposal, formData, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setProposalLoading(false);
    }
  };

  const finalizeProposal = async (formData, { onSuccess, onFailed }) => {
    try {
      setProposalLoading(true);
      await BaseClient.put(APIEndpoints.finalizeProposal, formData, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setProposalLoading(false);
    }
  };
  const updateProposal = async (formData, { onSuccess, onFailed }) => {
    try {
      setProposalLoading(true);
      await BaseClient.put(APIEndpoints.updateProposal, formData, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setProposalLoading(false);
    }
  };
  const deleteProposal = async (id, { onSuccess, onFailed }) => {
    try {
      setProposalLoading(true);

      await BaseClient.delete(APIEndpoints.deleteProposalByAdmin + `/${id}`, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setProposalLoading(false);
    }
  };

  const finalize = async (id, { onSuccess, onFailed }) => {
    try {
      setProposalLoading(true);
      await BaseClient.put(APIEndpoints.finalizeProposalDirect + `/${id}`, [], {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setProposalLoading(false);
    }
  };
  const getFinalisedProposals = async (payload, { onSuccess, onFailed }) => {
    try {
      setProposalLoading(true);
      await BaseClient.post(APIEndpoints.fetchAllFinishedProposals, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setProposalLoading(false);
    }
  };

  //!filter by company id
  // const fetchAllProposalData = async () => {
  //   try {
  //     setProposalLoading(true);
  //     await BaseClient.get(APIEndpoints.proposalListAll, {
  //       onSuccess: (res) => {
  //         const proposal = res.data;
  //         const data = JSON.parse(sessionStorage.getItem("loggedCompany"));
  //         const id = data.id;
  //         if (data == null) {
  //           window.location.href = "/login";
  //         }
  //         const filterProposal =
  //           proposal && proposal.filter((x) => x?.companyDetails[0]?.id == id);
  //         setAllProposals(filterProposal);
  //       },
  //       onFailed: (err) => {
  //         console.log(err);
  //       },
  //     });
  //   } finally {
  //     setProposalLoading(false);
  //   }
  // };

  const fetchAllProposalData = async () => {
    try {
      setProposalLoading(true);
      await BaseClient.get(APIEndpoints.proposalListAll, {
        onSuccess: (res) => {
          setProposalsList(res.data);
        },
        onFailed: (err) => {
          console.log("ERROR ON FETCHING ALL PROPOSALS LIST =>", err);
        },
      });
    } finally {
      setProposalLoading(false);
    }
  };

  const getAllProposalContracts = async () => {
    try {
      setProposalLoading(true);
      await BaseClient.get(APIEndpoints.fetchAllProposalContracts, {
        onSuccess: (res) => {
          setProposalContracts(res.data);
        },
        onFailed: (err) => {
          console.log(err);
        },
      });
    } finally {
      setProposalLoading(false);
    }
  };

  const getAllInvoicesList = async () => {
    try {
      setProposalLoading(true);
      await BaseClient.get(APIEndpoints.fetchAllInvoiceList, {
        onSuccess: (res) => {
          setProposalInvoices(res.data);
        },
        onFailed: (err) => {
          console.log(err);
        },
      });
    } finally {
      setProposalLoading(false);
    }
  };

  const getAllMailTemplates = async () => {
    try {
      setMailLoading(true);
      await BaseClient.get(APIEndpoints.getAllMailTemplates, {
        onSuccess: (res) => {
          setMailTemplates(res.data);
        },
        onFailed: (err) => {
          console.log(err);
        },
      });
    } finally {
      setMailLoading(false);
    }
  };

  const saveAsDraft = async (payload, { onSuccess, onFailed }) => {
    try {
      setDraftLoading(true);
      await BaseClient.post(APIEndpoints.saveDraftData, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setDraftLoading(false);
    }
  };

  const updateDraftDetails = async (payload, { onSuccess, onFailed }) => {
    try {
      setDraftLoading(true);
      await BaseClient.post(APIEndpoints.updateDraftData, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setDraftLoading(false);
    }
  };

  const fetchAllDrafts = async () => {
    try {
      setDraftLoading(true);

      await BaseClient.get(APIEndpoints.getAllDraftData, {
        onSuccess: (res) => {
          if (res && res.data) {
            setDraftsList(res.data);
          }
        },
        onFailed: (err) => {
          console.log("Error on fetching drafts list");
        },
      });
    } finally {
      setDraftLoading(false);
    }
  };

  const deleteProposalDraft = async (id, { onSuccess, onFailed }) => {
    try {
      setDraftLoading(true);

      await BaseClient.delete(APIEndpoints.deleteDraft + `/${id}`, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setDraftLoading(false);
    }
  };
  return {
    getFinalisedProposals,
    getAllTemplates,
    proposalSending,
    templates,
    deleteProposal,
    proposalLoading,
    getAllProposals,
    finalizeProposal,
    getNewFinalizedProposals,
    updateProposal,
    fetchAllProposalData,
    allProposals,
    createSingleProposal,
    proposalContracts,
    getAllProposalContracts,
    getAllInvoicesList,
    proposalInvoices,
    getAllMailTemplates,
    mailLoading,
    finalize,
    mailTemplates,
    fetchProposalByleadId,
    proposalsList,
    fetchAllDrafts,
    draftsList,
    draftLoading,
    deleteProposalDraft,
    saveAsDraft,
    updateDraftDetails
  };
};
export default useProposal;
