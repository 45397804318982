import React, { useContext, useEffect, useState } from "react";
import Utils from "../../Utils/utils";
import Sidebar from "../../Components/Sidebar";
import * as Bi from "react-icons/bi";
import TextArea from "antd/es/input/TextArea";
import { AppContext } from "../../Context/AppContext";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";

function UpdatePayment() {
  const { updatePaymentStatus, paymentLoading } = useContext(AppContext);
  const invoiceDetails = JSON.parse(sessionStorage.getItem("updatePayment"));
  const [paymentData, setPaymentData] = useState(0);
  const data = invoiceDetails?.productDetails;
  const details = data ? JSON.parse(data) : null;
  const productDetails = details ? details[0] : null;

  const [initialState, setInitialState] = useState({
    trId: "",
    act_amt: invoiceDetails?.bal_amt,
    rcv_amt: 0,
    tr_date: "",
    remarks: "",
    status: "",
  });

  useEffect(() => {
    if (!invoiceDetails) return;
    const amtdata = invoiceDetails?.payment_history;
    const price =
      amtdata && amtdata.length != 0 ? amtdata[amtdata.length - 1] : 0;

    const balance =
      price != 0 ? price?.act_amt - price?.rcv_amt : invoiceDetails?.total;

    setPaymentData(balance);
  }, [invoiceDetails]);

  const tax =
    ((productDetails?.price * productDetails?.quantity -
      productDetails?.discount) *
      productDetails?.tax) /
    100;

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setInitialState((prev) => {
      const newState = { ...prev, [name]: value };

      if (name === "act_amt" || name === "rcv_amt") {
        const actualAmount = parseFloat(newState.act_amt) || 0;
        const receivedAmount = parseFloat(newState.rcv_amt) || 0;

        if (receivedAmount > actualAmount) {
          // toast.error("Received amount cannot be higher than actual amount");
          newState.status = "";
        } else if (actualAmount > receivedAmount) {
          newState.status = "partially-paid";
        } else if (actualAmount === receivedAmount) {
          newState.status = "paid";
        } else {
          newState.status = "";
        }
      }

      return newState;
    });
  };

  const validate = () => {
    const { trId, act_amt, rcv_amt, tr_date } = initialState;

    if (!trId) {
      toast.error("Transaction ID is required");
      return false;
    }
    if (act_amt <= 0) {
      toast.error("Actual amount should not be less than or equal to 0");
      return false;
    }
    if (rcv_amt <= 0) {
      toast.error("Received amount should not be less than or equal to 0");
      return false;
    }
    if (!tr_date) {
      toast.error("Transaction date is required");
      return false;
    }
    if (parseFloat(rcv_amt) > parseFloat(act_amt)) {
      toast.error("Received amount cannot be higher than actual amount");
      return false;
    }
    if (initialState.trId.length <= 6) {
      toast.error("Transaction ID must be more than 6 characters!");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validate()) {
      return;
    }

    const payload = {
      trId: initialState.trId,
      invId: invoiceDetails?.invoiceId,
      leadId: invoiceDetails?.leadId,
      act_amt: initialState.act_amt,
      rcv_amt: initialState.rcv_amt,
      tr_date: initialState.tr_date,
      remarks: initialState.remarks,
      // status: initialState.status,
    };
    await updatePaymentStatus(payload, {
      onSuccess: async (res) => {
        toast.success("Updated successfully!");
        setTimeout(() => {
          window.location.href = "/custom-invoice";
        }, 1000);
      },
      onFailed: (err) => {
        toast.error("Failed to update:", err);
      },
    });
  };

  const prodData =
    invoiceDetails && invoiceDetails?.productDetails
      ? JSON.parse(invoiceDetails?.productDetails)
      : [];
  console.log("productDetails", productDetails);
  return (
    <div className="container-fluid" style={{ background: "" }}>
      <Sidebar>
        <Toaster position="top-center" />
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <h5>
                <b>{invoiceDetails?.invoiceNum}</b>
              </h5>
              <p>
                <b>Invoice To,</b>
                <br />
                {invoiceDetails?.cust_name ?? "N/A"} <br />
                {invoiceDetails?.cust_email ?? "N/A"} <br />
                {invoiceDetails?.billingAddress ?? "N/A"}
              </p>
            </div>
            <div className="col-md-6 col-sm-12 text-end">
              Inv Date:{" "}
              {Utils.formatDate(invoiceDetails?.invoice_date ?? "N/A")} <br />
              Due Date: {Utils.formatDate(
                invoiceDetails?.due_date ?? "N/A"
              )}{" "}
              <br />
              <a
                className="ref"
                href={
                  process.env.REACT_APP_AWS_IMAGE_URL +
                  "invoices/" +
                  invoiceDetails?.file
                }
                target="_blank"
                rel="noreferrer"
                style={{ color: "red" }}
              >
                <b> Download Invoice</b> <Bi.BiDownload />
              </a>
            </div>
          </div>
          <div
            className="row"
            style={{ marginTop: "50px", marginBottom: "10px" }}
          >
            Invoice Details
            <table className="table table-bordered rwd-table">
              <thead>
                <tr>
                  {" "}
                  <th>Sl no </th>
                  <th>Name</th>
                  <th>Courses</th>
                  {/* <th>Quantity</th> */}
                  {/* <th>Sub. Type</th> */}
                  {/* <th>Sub Total</th> */}
                  <th>Discount</th>
                  <th>VAT(%)</th>
                  <th>VAT Amount</th>
                  <th>Deposit</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-th="Supplier Code">#1</td>
                  <td data-th="Supplier Name">
                    {productDetails?.productName ?? "N/A"}
                  </td>
                  <td>
                    {prodData[0]?.services?.map((x) => {
                      return <span>{x.courseHead ?? "N/A"},</span>;
                    })}
                  </td>
                  {/* <td data-th="Supplier Name">
                    {productDetails?.quantity ?? "N/A"}
                  </td> */}
                  {/* <td data-th="Invoice Number">
                    {invoiceDetails?.subscription ?? "N/A"}
                  </td> */}
                  {/* <td data-th="Invoice Date">{prodData[0]?.price ?? "N/A"}</td> */}
                  <td data-th="Invoice Date">
                    {prodData[0]?.discount ?? "N/A"}
                  </td>
                  <td data-th="Due Date">{prodData[0]?.tax ?? "N/A"}</td>
                  <td data-th="Due Date">
                    {prodData[0]?.taxAmount &&
                    typeof prodData[0]?.taxAmount === "string"
                      ? parseFloat(prodData[0]?.taxAmount).toFixed(2)
                      : prodData[0]?.taxAmount?.toFixed(2)}
                  </td>
                  <td data-th="Due Date">{prodData[0]?.deposit ?? "N/A"}</td>
                  <td data-th="Net Amount">£{invoiceDetails?.total ?? "00"}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ width: "75%" }} className="row">
            <table className="table table-bordered rwd-table">
              <thead>
                <tr>
                  <th>Service</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {productDetails?.items?.length != 0 &&
                  productDetails?.items?.map((data, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>{data?.description ?? "N/A"}</td>
                          <td>
                            {data?.price == "" || data?.price == 0
                              ? 0
                              : data?.price}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                {productDetails?.elements?.length != 0 &&
                  productDetails?.elements?.map((data, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>{data?.item ?? "N/A"}</td>
                          <td>{data?.price ?? 0}</td>
                        </tr>
                      </>
                    );
                  })}
                {productDetails?.services?.length != 0 &&
                  productDetails?.services?.map((data, sindex) => {
                    const serviceList = data?.items;
                    {
                      serviceList &&
                        serviceList.map((main, iiindex) => {
                          if (main?.value != 0 || main?.value != "0") {
                          }
                        });
                    }
                    return (
                      <tr key={sindex}>
                        <td>
                          {data?.courseHead ?? "N/A"}
                          <br />
                          {serviceList &&
                            serviceList?.map((list, iindex) => {
                              if (list?.value != 0 || list?.value != "0") {
                                return (
                                  <>
                                    <ul className="mt-2" key={iindex}>
                                      <li>
                                        {list?.head == ""
                                          ? "N/A"
                                          : list?.head + " - "}
                                        {"£" + list?.value ?? 0}
                                      </li>
                                    </ul>
                                  </>
                                );
                              }
                            })}
                        </td>
                        <td>
                          {data.price == 0 ? data?.price ?? 0 : ""}
                          {serviceList &&
                            serviceList?.map((list, iindex) => {
                              if (list?.value == 0 || list?.value == "0") {
                                return <>0</>;
                              } else {
                                return (
                                  <div key={iindex}>
                                    {"£" + list?.value ?? 0} <br /> <br />
                                  </div>
                                );
                              }
                            })}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <h6>Meal Details</h6>

          {invoiceDetails &&
            invoiceDetails?.payment_history &&
            invoiceDetails?.payment_history.length != 0 && (
              <div className="row" style={{ marginBottom: "30px" }}>
                Transaction History
                <table className="table table-bordered rwd-table">
                  <thead className="table-light">
                    <tr>
                      {" "}
                      <th>Sl no </th>
                      <th> Transaction Id</th>
                      <th>Date</th>
                      <th>Actual Amount</th>
                      <th> Received Amount</th>
                      <th>Balance Payable</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceDetails?.payment_history &&
                      invoiceDetails?.payment_history != undefined &&
                      invoiceDetails?.payment_history?.map((data, index) => {
                        console.log(data, "data of inv");
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{data?.trId ?? "N/A"}</td>
                            <td>{Utils.formatDate(data?.tr_date)}</td>
                            <td> {data?.act_amt ?? "N/A"}</td>
                            <td>{data?.rcv_amt ?? "N/A"}</td>
                            <td>{data?.bal_amt ?? "0"}</td>
                            <td> {data?.remarks ?? "N/A"}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
        </div>
        <div className="row m-1">
          {invoiceDetails?.payement_status != "paid" ? (
            <div className="card p-3">
              <small style={{ color: "green" }}>Update Payment Status</small>
              <div className="row py-3">
                <div className="col-md-4 col-lg-3 col-sm-12">
                  <label>Transaction Id</label>
                  <input
                    className="form-control mb-2"
                    type="text"
                    minLength={6}
                    value={initialState.trId}
                    onChange={handleOnChange}
                    name="trId"
                  />
                </div>

                <div className="col-md-4 col-lg-2 col-sm-12">
                  <label>Date of Payment</label>
                  <input
                    className="form-control"
                    type="date"
                    onChange={handleOnChange}
                    value={initialState.tr_date}
                    name="tr_date"
                  />
                </div>
                <div className="col-md-4 col-lg-3 col-sm-12">
                  <label>Actual Amount</label>
                  <input
                    className="form-control mb-2"
                    type="number"
                    value={initialState.act_amt}
                    name="act_amt"
                    min={1}
                    disabled
                    onChange={handleOnChange}
                  />
                </div>
                <div className="col-md-4 col-lg-3 col-sm-12">
                  <label>Amount Received</label>
                  <input
                    className="form-control mb-2"
                    type="number"
                    min={1}
                    onChange={handleOnChange}
                    value={initialState.rcv_amt}
                    name="rcv_amt"
                  />
                </div>
                <div className="col-md-4 col-lg-5 col-sm-12">
                  <label>Remarks</label>
                  <TextArea
                    className="form-control mb-2"
                    type="text"
                    onChange={handleOnChange}
                    value={initialState.remarks}
                    name="remarks"
                  />
                </div>
              </div>
              <div className="text-center mx-auto">
                {paymentLoading ? (
                  <button className="pay_btn" disabled>
                    Submitting...
                  </button>
                ) : (
                  <button className="pay_btn" onClick={handleSubmit}>
                    Submit
                  </button>
                )}
              </div>
            </div>
          ) : (
            <p style={{ color: "red" }} className="text-center">
              Payment Completed!
            </p>
          )}
        </div>
      </Sidebar>
    </div>
  );
}

export default UpdatePayment;
