import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "../../Style/Style.css";
import Logo from "../../Assets/pakawaanlogoo.png";
import Utils from "../../Utils/utils";
import { AppContext } from "../../Context/AppContext";
import html2canvas from "html2canvas";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import jsPDF from "jspdf";
import SendMailTemplateModal from "../../Components/mail/SendMailTemplateModal";

function InvoiceSample() {
  const {
    updateInvoiceFile,
    getAllInvoices,
    balanceDue,
    fetchAllProposalData,
  } = useContext(AppContext);
  const targetRef = useRef();
  const isFirstLoad = useRef(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const InvoiceNumber = JSON.parse(sessionStorage.getItem("invoiceNumber"));

  const [parsedBody, setParsedBody] = useState(null);
  const [mailSubject, setMailSubject] = useState(null);
  const [ccValue, setCcValue] = useState(null);
  const data = JSON.parse(sessionStorage.getItem("invoiceDetails"));
  const invoiceDetails = JSON.parse(sessionStorage.getItem("invoiceDetails"));
  const packageDetails = JSON.parse(sessionStorage.getItem("packageDetails"));

  console.log("packageDetails", packageDetails);

  const bankData = data?.bankDetails;
  const bankDetails = JSON.parse(bankData);
  const productArray = JSON.parse(data?.productDetails);
  const [numPages, setNumPages] = useState(1);
  const nav = JSON.parse(sessionStorage.getItem("data"));
  const [showMailModal, setShowMailModal] = useState(false);

  console.log("productArray", data);

  // const onBackButtonEvent = (e) => {
  //   e.preventDefault();
  //   if (!finishStatus) {
  //     if (window.confirm("Do you want to go back ?")) {
  //       setfinishStatus(true);
  //       // your logic
  //       console.log("back button clicked");
  //     } else {
  //       window.history.pushState(null, null, window.location.pathname);
  //       setfinishStatus(false);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   window.history.pushState(null, null, window.location.pathname);
  //   window.addEventListener("popstate", onBackButtonEvent);
  //   return () => {
  //     window.removeEventListener("popstate", onBackButtonEvent);
  //   };
  // }, []);

  useEffect(() => {
    const calculateNumPages = () => {
      const totalPages = Math.ceil(
        targetRef.current.offsetHeight / window.innerHeight
      );
      setNumPages(totalPages);
    };

    window.addEventListener("resize", calculateNumPages);

    return () => {
      window.removeEventListener("resize", calculateNumPages);
    };
  }, []);

  const handleUpdatePDF = async () => {
    setShowMailModal(true);
  };
  const sendMail = async () => {
    setLoading(true);

    const canvas = await html2canvas(targetRef.current, {
      imageTimeout: 0,
      scale: 2,
      width: targetRef.current.offsetWidth,
      height: targetRef.current.offsetHeight,
      allowTaint: true,
      useCORS: true,
    });

    const imgData = canvas.toDataURL("image/jpeg", 0.95);

    // Create PDF
    const pdf = new jsPDF("p", "mm", "a4");
    pdf.addImage(
      imgData,
      "JPEG",
      0,
      0,
      pdf.internal.pageSize.getWidth(),
      pdf.internal.pageSize.getHeight()
    );

    const pdfBlob = pdf.output("blob");
    const mailContent = {
      subject: mailSubject,
      body: parsedBody?.data ? parsedBody?.data : parsedBody,
      cc: ccValue ?? "",
    };
    const formData = new FormData();
    formData.append("file", pdfBlob, "Invoice.pdf");
    formData.append("invoiceId", id);
    formData.append("invoiceNum", InvoiceNumber?.invoiceNum);
    formData.append("mailContent", JSON.stringify(mailContent));
    await updateInvoiceFile(formData, id, {
      onSuccess: async (res) => {
        toast.success("Successfully sent Invoice");
        setLoading(false);
        setShowMailModal(false);
        await fetchAllProposalData();
        await getAllInvoices();
        setTimeout(() => {
          sessionStorage.removeItem("DirectProposal");
          sessionStorage.removeItem("selectedProduct");
          sessionStorage.removeItem("invoiceDetails");
          sessionStorage.removeItem("packageDetails");
          sessionStorage.removeItem("invoiceNumber");
          navigate("/custom-invoice");
        }, 1000);
      },
      onFailed: (err) => {
        setLoading(false);
        setShowMailModal(false);
        if (err != null) {
          toast.error(err.errormessage);
        } else {
          toast.error("Something Went Wrong!");
        }
      },
    });
  };

  console.log("productArray", packageDetails);

  const itemsTotal =
    packageDetails &&
    packageDetails?.itemsArray &&
    packageDetails?.itemsArray?.reduce((acc, item) => {
      const itemPrice = parseFloat(item.price) || 0;
      return acc + itemPrice;
    }, 0);

  return (
    <Fragment>
      {/* <div className="showAlert_wrapper">
        <div className="card showAlert_card">
          <p className="title">
            <i>
              <FaCircleInfo />
            </i>{" "}
            Are you sure?
          </p>
          <p className="content__">
            If you navigate away from this page, the generated invoice will be
            automatically cancelled. Are you sure you want to go back?
          </p>
        </div>
      </div> */}
      {showMailModal && (
        <SendMailTemplateModal
          showMailModal={showMailModal}
          setShowMailModal={setShowMailModal}
          sendMail={sendMail}
          parsedBody={parsedBody}
          setParsedBody={setParsedBody}
          mailSubject={mailSubject}
          setMailSubject={setMailSubject}
          ccValue={ccValue}
          setCcValue={setCcValue}
          data={data}
          title="invoice"
        />
      )}
      <div style={{ background: "#eaeff1" }} className="container-fluid">
        <Toaster position="top-center" />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {loading ? (
                <button className="btn-SEND" type="button" disabled>
                  Sending...
                </button>
              ) : (
                <>
                  <button
                    className="btn-SEND"
                    type="button"
                    onClick={handleUpdatePDF}
                  >
                    Send
                  </button>
                  <button
                    className="btn-SEND"
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                </>
              )}
            </div>
          </div>
        </div>{" "}
        <>
          <div className="row">
            <div className="col-sm-12 mx-auto mt-3 mb-3">
              <div className="inv_bg" ref={targetRef}>
                <div className="col-md-12">
                  <div className="container inv">
                    <div className="row">
                      <div className="col-sm-6">
                        <div class="custom-col">
                          <h1 className="inv_head">Invoice</h1>

                          {/* <p class="invoice_Add">
                          30A Ipswich Road, Colchester, United Kingdom
                          <br />
                          <small>info@foodpage.co.uk </small>
                          <br />
                          <small> +44 752 553 60 15</small>
                        </p> */}
                        </div>
                        <div
                          className="custom-col"
                          style={{
                            marginTop: "117px",
                            color: "#595957",
                          }}
                        >
                          <small>Invoice To,</small>
                          <h6
                            style={{
                              color: "#000",
                              fontWeight: "600",
                              position: "relative",
                              top: "5px",
                            }}
                          >
                            {invoiceDetails?.cust_name ?? "NA"}
                          </h6>
                          <p
                            class="invoice_Add"
                            style={{
                              position: "relative",
                              // top: "10px",
                            }}
                          >
                            {invoiceDetails?.billingAddress ?? "NA"}
                            <br />
                            <p>{invoiceDetails?.cust_email ?? "NA"} </p>
                            <br />
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-2"></div>
                      <div className="col-sm-4">
                        <div class="logo-arera">
                          <img src={Logo} />
                        </div>
                        <div class="table-Info">
                          <table>
                            <tr>
                              <td>
                                <b> Invoice Number</b>

                                <br />
                              </td>
                              <td> {InvoiceNumber?.invoiceNum}</td>
                            </tr>
                            <tr></tr>
                            <tr>
                              <td>
                                <b> Invoice Date</b>
                              </td>
                              <td>
                                {" "}
                                <small>
                                  {Utils.formatDate(
                                    invoiceDetails?.invoice_date ?? "NA"
                                  )}
                                </small>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b> Due Date</b>

                                <br />
                              </td>
                              <td>
                                <small>
                                  {Utils.formatDate(
                                    invoiceDetails?.due_date ?? "NA"
                                  )}
                                </small>
                              </td>
                            </tr>
                          </table>
                          <div
                            className="p-1"
                            style={{
                              color: "#595957",
                              // position: "relative",
                              // top: "25px",
                            }}
                          >
                            <small>Invoice From,</small>

                            <h6
                              style={{
                                color: "#000",
                                fontWeight: "600",
                                // position: "relative",
                                // top: "5px",
                              }}
                            >
                              Pakwaan
                            </h6>
                            <p
                              class="invoice_Add"
                              style={{
                                // position: "relative",
                                // top: "7px",
                                width: "100%",
                                paddingRight: "30px",
                              }}
                            >
                              Pakwaan Indian Punjabi Cuisine 1 Bridge St,
                              Writtle, Chelmsford CM1 3EY <br />
                              <p>
                                info@pakwaan.co.uk
                                <br />
                                <small> +44 1245 422 891</small>
                              </p>
                              <br />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-1"></div>
                    </div>
                    <div
                      className="row mx-auto"
                      // style={{ position: "relative", top: "50px" }}
                    >
                      <td>
                        {" "}
                        <small style={{ fontSize: "12px" }}>
                          <b>{packageDetails?.packageName ?? ""}</b>
                        </small>
                        <p style={{ fontSize: "10px" }}>
                          {" "}
                          {packageDetails?.packageDescription ?? ""}
                        </p>
                      </td>
                      <td style={{ height: "100%", width: "100%" }}></td>

                      <div className="col-sm-12 mx-auto">
                        <table className="in_table">
                          <tr
                            className="under_line"
                            style={{
                              background: "#C40C0C",
                              color: "#fff",
                              padding: "10px",
                            }}
                          >
                            {/* <th colspan={1}>#</th> */}

                            <th colSpan={6}>Item</th>

                            <th className="text-end">Price</th>
                          </tr>
                          <>
                            {packageDetails?.services &&
                              packageDetails?.services.map((info, index) => {
                                const serviceList = info?.items;
                                const totalValue =
                                  serviceList &&
                                  serviceList.reduce((acc, item) => {
                                    const itemValue =
                                      typeof item.value === "string"
                                        ? parseFloat(item.value) || 0
                                        : typeof item.value === "number"
                                        ? item.value
                                        : 0;
                                    return acc + itemValue;
                                  }, 0);

                                const shouldShowHead =
                                  packageDetails.proposalInfo?.showZeroValues ||
                                  (serviceList &&
                                    serviceList.some(
                                      (item) =>
                                        item.value !== 0 && item.value !== ""
                                    ));

                                if (!shouldShowHead) {
                                  return null;
                                }

                                return (
                                  <Fragment key={index}>
                                    <tr
                                      className="under_line"
                                      style={{
                                        background: "#eee",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <td colSpan={6}>
                                        <p className="mb-1 ps-2">
                                          {info?.courseHead ?? "N/A"}
                                        </p>
                                        <ul>
                                          {serviceList &&
                                            serviceList.map((data, key) => {
                                              if (
                                                data?.head &&
                                                (packageDetails.proposalInfo
                                                  ?.showZeroValues ||
                                                  (data.value !== 0 &&
                                                    data.value !== ""))
                                              ) {
                                                return (
                                                  <li
                                                    className="mt-2"
                                                    key={key}
                                                  >
                                                    {data?.head ?? "N/A"} - £{" "}
                                                    {data?.value ?? 0}
                                                  </li>
                                                );
                                              }
                                              return;
                                            })}
                                        </ul>
                                      </td>
                                      <td colSpan={1}>
                                        <p
                                          style={{
                                            textAlign: "right",
                                            alignItems: "right",
                                          }}
                                          className="pe-3"
                                        >
                                          £ {totalValue ?? 0}
                                        </p>
                                      </td>
                                    </tr>
                                  </Fragment>
                                );
                              })}

                            {packageDetails &&
                              packageDetails?.elementArray &&
                              Array.isArray(packageDetails?.elementArray) &&
                              packageDetails?.elementArray.length !== 0 &&
                              packageDetails?.elementArray.map(
                                (item, index) => {
                                  const price = item.price;

                                  // Check if item has a valid name and price
                                  if (
                                    item.item.length !== 0 &&
                                    price !== 0 &&
                                    price !== ""
                                  ) {
                                    return (
                                      <tr
                                        className="under_line"
                                        style={{
                                          background: "#eee",
                                          fontSize: "14px",
                                        }}
                                        key={index} // Always include a key prop for list items
                                      >
                                        <td className="ps-2" colSpan={6}>
                                          {item?.item ?? "N/A"}
                                        </td>
                                        <td className="pe-3">
                                          <p style={{ textAlign: "right" }}>
                                            £ {price}
                                          </p>
                                        </td>
                                      </tr>
                                    );
                                  }
                                  return;
                                }
                              )}

                            {packageDetails &&
                              packageDetails?.itemsArray &&
                              Array.isArray(packageDetails?.itemsArray) &&
                              packageDetails?.itemsArray.length !== 0 &&
                              packageDetails?.itemsArray.map((item, index) => {
                                const price = item.price;

                                if (
                                  price !== 0 &&
                                  price !== "0" &&
                                  price !== ""
                                ) {
                                  return (
                                    <tr
                                      className="under_line"
                                      style={{
                                        background: "#eee",
                                        fontSize: "14px",
                                      }}
                                      key={index}
                                    >
                                      <td colSpan={6}>
                                        <p>{item.description ?? "N/A"}</p>
                                      </td>

                                      <td
                                        style={{ textAlign: "right" }}
                                        className="pe-3"
                                      >
                                        £ {price}
                                      </td>
                                    </tr>
                                  );
                                }
                                return;
                              })}
                          </>
                          <tbody>
                            {productArray != null &&
                              productArray.map((data, index) => {
                                const taxamount =
                                  ((data?.price * data?.quantity -
                                    data?.discount) *
                                    data?.tax) /
                                  100;
                                const totalValue =
                                  data.price * data.quantity -
                                  data.discount +
                                  taxamount;
                                const totalPayable =
                                  totalValue - invoiceDetails.deposit;
                                const itemDetails = data.items;

                                console.log("data=>", productArray);

                                return (
                                  <>
                                    <>
                                      <tr
                                        className="under_line"
                                        style={{
                                          background: "#eee",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {/* <td colspan={1}>{index + 1}</td> */}
                                        <td colSpan={6}>
                                          <div className="pro-feat-000">
                                            {/* <b>{data?.productName ?? "NA"}</b> */}

                                            {/* {packageDetails &&
                                              packageDetails?.elementArray &&
                                              Array.isArray(
                                                packageDetails?.elementArray
                                              ) &&
                                              packageDetails?.elementArray
                                                .length != 0 &&
                                              packageDetails?.elementArray.map(
                                                (item, index) => {
                                                  if (item?.item.length != 0) {
                                                    return (
                                                      <tr key={index}>
                                                        <td>
                                                          {item?.item ?? "N/A"}
                                                        </td>
                                                        <td>
                                                          {item?.price ?? 0}
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                }
                                              )} */}
                                            {/* {packageDetails &&
                                              packageDetails?.services &&
                                              Array.isArray(
                                                packageDetails?.services
                                              ) &&
                                              packageDetails?.services.length !=
                                                0 &&
                                              packageDetails?.services.map(
                                                (item, index) => {
                                                  return (
                                                    <tr key={index}>
                                                      <td>
                                                        {item?.courseHead ??
                                                          "N/A"}
                                                      </td>
                                                      <td>{item.value ?? 0}</td>
                                                    </tr>
                                                  );
                                                }
                                              )} */}
                                            <br />

                                            <small
                                              className="mr-3"
                                              style={{
                                                paddingRight: "20px",
                                              }}
                                            >
                                              {" "}
                                              {bankDetails?.productDescription}
                                            </small>
                                          </div>
                                        </td>

                                        <td className="position-relative">
                                          {/* <p className="text_btm_">
                                            £ {itemsTotal ?? 0}
                                          </p> */}
                                        </td>
                                      </tr>
                                      <table className="table invoice_table"></table>
                                      {/* <tr style={{ height: "30px" }}>
                                        <td> </td>
                                        <td> </td>
                                        <td> </td>
                                        <td> </td>
                                        <td> </td>
                                      </tr> */}
                                      <tr style={{ textAlign: "right" }}>
                                        <td> </td>
                                        <td colSpan={4}></td>
                                        <td className="">Sub Total</td>
                                        <td className="">
                                          £{" "}
                                          {packageDetails?.values?.allTotal ??
                                            0}
                                        </td>
                                      </tr>
                                      <tr style={{ textAlign: "right" }}>
                                        <td> </td>
                                        <td colSpan={4}></td>
                                        <td className="">Discount </td>
                                        <td className="">
                                          {data.discount ?? "N/A"}
                                        </td>
                                      </tr>

                                      <>
                                        {" "}
                                        <tr style={{ textAlign: "right" }}>
                                          <td> </td>
                                          <td colSpan={4}></td>
                                          <td className="">VAT({data.tax}%)</td>
                                          <td className="">
                                            {data?.taxAmount ?? 0}
                                          </td>
                                        </tr>
                                      </>

                                      <tr style={{ textAlign: "right" }}>
                                        <td> </td>
                                        <td colSpan={4}></td>
                                        <td className="">Total</td>
                                        <td className="">
                                          {/* {invoiceDetails.total} */}
                                          {/* {totalValue ?? "N/A"} */}
                                          {data.total ?? "N/A"}
                                        </td>
                                      </tr>
                                      {invoiceDetails.deposit != 0 &&
                                        invoiceDetails.deposit != null &&
                                        invoiceDetails.deposit != undefined && (
                                          <tr style={{ textAlign: "right" }}>
                                            <td> </td>
                                            <td colSpan={4}></td>
                                            <td className="">Deposit(-)</td>
                                            <td className="">
                                              {invoiceDetails.deposit ?? "N/A"}
                                            </td>
                                          </tr>
                                        )}

                                      <tr
                                        style={{
                                          textAlign: "right",
                                        }}
                                      >
                                        <td> </td>
                                        <td colSpan={4}></td>
                                        <td
                                          style={{
                                            // background: "#eee",
                                            padding: "8px",
                                          }}
                                        >
                                          <b>Total Due</b>
                                        </td>
                                        <td
                                          style={{
                                            // background: "#eee",
                                            padding: "8px 4px",
                                            fontSize: "14px",
                                          }}
                                        >
                                          <b>
                                            £ {invoiceDetails.total}
                                            {/* £{totalPayable ?? "N/A"} */}
                                          </b>
                                        </td>
                                      </tr>
                                    </>
                                  </>
                                );
                              })}
                          </tbody>
                        </table>
                        {bankDetails.message != null &&
                          bankDetails.message != undefined &&
                          bankDetails.message != "" && (
                            <div className="col-md-12 mt-3">
                              <p
                                style={{
                                  textAlign: "justify",
                                  fontSize: "12px",
                                  fontFamily: "poppins",
                                }}
                              >
                                  Notes : {bankDetails?.message ?? "N/A"}
                              </p>
                            </div>
                          )}
                        <div className="brk_div"></div>
                        <div className="col-md-6"></div>
                        <div
                          className="col-sm-5"
                          style={{
                            position: "relative",
                            bottom: "0",
                            top: "0%",
                          }}
                        >
                          <div className="more_details card p-3">
                            <h4>PAYMENT METHOD</h4>

                            {/* <h6>Ferns IT Solutions Private Limited  <br /></h6> */}
                            <p>
                              {" "}
                              {bankDetails?.bank ?? "NA"}
                              {" : "}
                              {bankDetails?.paymentAccount ?? "NA"}
                              <br />{" "}
                              {bankDetails?.ifsc?.length != 0 &&
                                bankDetails?.ifsc != null && (
                                  <>
                                    {" "}
                                    IFSC :{bankDetails?.ifsc ?? "NA"}
                                    <br />
                                  </>
                                )}
                              {bankDetails?.branch ?? "NA"}
                              <br />
                              {bankDetails?.swifCode?.length != 0 &&
                                bankDetails?.swifCode != null && (
                                  <>
                                    {" "}
                                    SwiftCode :{bankDetails?.swifCode ?? "NA"}
                                    <br />
                                  </>
                                )}
                              <br />
                              {bankDetails?.sortCode?.length != 0 &&
                                bankDetails?.bankDetails?.sortCode != null && (
                                  <>
                                    {" "}
                                    SortCode :
                                    {bankDetails?.bankDetails?.sortCode ?? "NA"}
                                    <br />
                                  </>
                                )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p
                  style={{
                    position: "absolute",
                    bottom: "0",
                    textAlign: "center",
                    fontSize: "10px",
                    left: "30%",
                    right: "30%",
                  }}
                >
                  System Generated Invoice
                </p>
              </div>
            </div>
          </div>
        </>
      </div>
    </Fragment>
  );
}

export default InvoiceSample;
