import React, { useEffect, useState } from "react";
import Dashboard from "../Components/Sidebar";
// import Board1 from "../Assets/Dashboard/beach-bag.png";
// import Board2 from "../Assets/Dashboard/growth-up-arrow-icon-isolated.jpg";
import Board1 from "../Assets/Dashboard/MicrosoftTeams-image (8).png";
import Board2 from "../Assets/Dashboard/MicrosoftTeams-image (6).png";
import Board3 from "../Assets/Dashboard/service.png";
import Board4 from "../Assets/Dashboard/MicrosoftTeams-image (7).png";
import ReactPaginate from "react-paginate";
import { useContext } from "react";
import * as Bi from "react-icons/bi";
import * as Md from "react-icons/md";
import { AppContext } from "../Context/AppContext";
import PieChart from "./Charts/PieChart";
import "../Style/Style.css";
import CountUp from "react-countup";
// import About from "../Assets/paper-cut-two-business-man-shake-hand.jpg"

function SuperAdmin() {
  const {
    productList,
    allSellerEnquiries,
    sellerList,
    reportList,
    invoiceList,
    proposalsList,
  } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [pageOffset, setPageOffset] = useState(0);
  console.log("invoice", invoiceList);
  sessionStorage.removeItem("draftProposalInfo");
  sessionStorage.removeItem("proposalData");
  sessionStorage.removeItem("prodraftd");
  const enquiriesLength = allSellerEnquiries.length;
  const proposal = proposalsList?.length;
  const invoice = invoiceList?.length;
  const fetchRegistered = {
    Merchant: sellerList.filter((reg) => reg?.partner_seller_status === 1),
  };

  const NumberofMerchants = fetchRegistered.Merchant.length;
  const Products = productList.length;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = allSellerEnquiries.slice(
    indexOfFirstItem,
    indexOfLastItem
  );


  return (
    <div>
      <div id="super-admin">
        <div className="container-fluid ">
          {/* <h6 className="head-h6 text-center mb-3">DASHBOARD </h6> */}
          {/* <div className="admin-img" >
            <img src={About} className="admin_img" />
            </div> */}

          <h5 className="head-h5 text-center mt-5 mb-3">Welcome to Pakwaan</h5>
          <div className="row mx-auto ">
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              <div className="mx-auto">
                <div className="row box-row">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div
                      className="card admin_card p-3 mb-3"
                      style={{ backgroundColor: "#FAF9F6" }}
                    >
                      <a href="/leads" className="avoid-line">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="dash-img-res">
                              <img
                                src={Board1}
                                alt="img"
                                className="dash-widgetimg dash_img"
                              />
                            </div>
                          </div>
                          <div className="col-md-8 mt-2">
                            <h6
                              style={{ textAlign: "center", fontSize: "15px" }}
                            >
                              Total Customers
                            </h6>
                            <h5
                              style={{ textAlign: "center", fontSize: "40px" }}
                            >
                              <b>{enquiriesLength ?? 0}</b>
                            </h5>
                            {/* <CountUp 
                            start={0} 
                            end={enquiriesLength} 
                            >{({countUpRef})=>(
                              <h5
                              ref={countUpRef} style={{ textAlign: "center", fontSize: "40px" }}
                            >
                              <b>{enquiriesLength ?? 0}</b>
                            </h5>
                            )}
                            
                            </CountUp> */}
                          </div>
                          {/* <div className="col-md-6 mt-3 total" >
                            Total Customers : <b>{NumberofMerchants}</b>
                          </div> */}
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <a href="/proposals" className="avoid-line">
                      <div
                        className="card admin_card p-3 mb-3"
                        style={{ backgroundColor: "#FAF9F6" }}
                      >
                        <div className="row">
                          <div className="col-md-4">
                            <div className="dash-img-res">
                              <img
                                src={Board2}
                                alt="img"
                                className="dash-widgetimg dash_img"
                              />
                            </div>
                          </div>
                          <div className="col-md-8 mt-2">
                            <h6
                              style={{ textAlign: "center", fontSize: "15px" }}
                            >
                              {" "}
                              Number Of Proposal
                            </h6>
                            <h5
                              style={{ textAlign: "center", fontSize: "40px" }}
                            >
                              <b>{proposal ?? 0}</b>
                            </h5>
                            {/* <CountUp
                            start={0} 
                            end={proposal} 
                            >{({countUpRef})=>(
                              <h5 ref={countUpRef}
                              style={{ textAlign: "center", fontSize: "40px" }}
                            >
                              <b>{proposal ?? 0}</b>
                            </h5>
                            )}</CountUp> */}
                          </div>

                          {/* <div className="col-md-6 mt-3 total" >
                            Number Of Enquiries : <b>{enquiriesLength}</b>
                          </div> */}
                        </div>
                      </div>
                    </a>
                  </div>
                  {/* <div className="col-lg-3 col-md-6 col-sm-12">
                    <a href="/products" className="avoid-line">
                      <div
                        className="card admin_card p-3 mb-3"
                        style={{ backgroundColor: "#FAF9F6" }}
                      >
                        <div className="row">
                          <div className="col-md-4">
                            <div className="dash-img-res">
                              <img
                                src={Board3}
                                alt="img"
                                className="dash-widgetimg dash_img"
                              />
                            </div>
                          </div>
                          <div className="col-md-8 mt-2">
                            <h6
                              style={{ textAlign: "center", fontSize: "15px" }}
                            >
                              Products/Services
                            </h6>
                            <CountUp
                            start={0}
                            end={Products}
                            delay={2}>{({countUpRef})=>(
                              
                              <h5
                              ref={countUpRef}
                              style={{ textAlign: "center", fontSize: "40px" }}
                            >
                              <b>{Products ?? 0}</b>
                            </h5>
                           
                            )}

                            </CountUp>
                           
                          </div>
                          <div className="col-md-6 mt-3 total" >
                          Products/Services : <b>{Products}</b>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div> */}
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <a href="/custom-invoice" className="avoid-line">
                      <div
                        className="card admin_card p-3 mb-3"
                        style={{ backgroundColor: "#FAF9F6" }}
                      >
                        <div className="row">
                          <div className="col-md-4">
                            <div className="dash-img-res">
                              <img
                                src={Board4}
                                alt="img"
                                className="dash-widgetimg dash_img"
                              />
                            </div>
                          </div>
                          <div className="col-md-8 mt-2">
                            <h6
                              style={{ textAlign: "center", fontSize: "15px" }}
                            >
                              Total Sales
                            </h6>
                            <h5
                              style={{ textAlign: "center", fontSize: "40px" }}
                            >
                              {" "}
                              <b>{invoice ?? 0}</b>
                            </h5>
                            {/* <CountUp
                            start={0}
                            end={invoice}
                            >{({countUpRef})=>(
                              <h5
                              ref={countUpRef}
                              style={{ textAlign: "center", fontSize: "40px" }}
                            >
                              <b>{invoice ?? 0}</b>
                            </h5>
                            )}</CountUp>
                             */}
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-lg-6 col-sm-6 col-12 d-flex mt-2">
                    <div className="dash-count admin_card row">
                      <div className="dash-counts col-md-10">
                        <h4 >{Products}</h4>
                        <h5>
                          <a href="/products" className="avoid-ln">
                            Products/Services
                          </a>
                        </h5>
                      </div>
                      <div className="dash-imgs col-md-2">
                        <i style={{fontSize:"35px"}}>
                          <Md.MdProductionQuantityLimits />
                        </i>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-6 col-12 d-flex mt-2">
                    <div className="dash-count das1 admin_card row">
                      <div className="dash-counts col-md-10">
                        <h4>100</h4>
                        <h5>Total Sales</h5>
                      </div>
                      <div className="dash-imgs col-md-2">
                        <i style={{fontSize:"35px"}}>
                          <Bi.BiSolidPurchaseTagAlt />
                        </i>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="card p-3 mt-2">
                <h6 className="text-center chart-head">
                  Monthly Report Of Enquiry
                </h6>

                <PieChart />
              </div>
            </div> */}
          </div>
        </div>
        {/* <div className="container-fluid  p-3 m-3">
          <h4 className="head-h4 mb-3">Enquiries </h4>
          <div className="card p-3 overflow">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Client Name</th>
                  <th scope="col">Shop Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Contact</th>
                  <th scope="col" className="text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {allSellerEnquiries != null &&
                  Array.isArray(allSellerEnquiries) &&
                  allSellerEnquiries.toReversed().map((enquiry, index) => {
                    const sellerData = enquiry?.sellerDetails;

                    return (
                      <>
                        <tr>
                          <td key={index}>{index + 1}</td>
                          <td>{sellerData?.sellerName ?? "N/A"}</td>
                          <td>{sellerData?.shopName ?? "N/A"}</td>
                          <td>{sellerData?.sellerEmail ?? "N/A"}</td>
                          <td>{sellerData?.mobile ?? "N/A"}</td>

                          <td className="text-center position-relative">
                            <div className="btn-toggle">
                              <button
                                className="one-btn view_enquiry_btn"
                                onClick={() => {
                                  window.location.href = `/view-enquiry/${enquiry.pceId}`;
                                }}
                              >
                                View
                              </button>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div> */}
        <section>
          {/* <div className="container-fluid  p-3 m-3">
            <h4 className="head-h4 mb-3">Recent Enquiries</h4>
            <div className="card p-3 overflow">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Shop Name</th>
                    <th scope="col">Shop URL</th>
                    <th scope="col">Email</th>
                    <th scope="col">Mobile</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(currentItems) &&
                    currentItems != null &&
                    currentItems.map((enquiry, index) => {
                      const data = enquiry.sellerDetails;

                      return (
                        <tr key={index}>
                          <th scope="row">
                            {pageOffset * itemsPerPage + index + 1}
                          </th>
                          <td>{data?.sellerName ?? "N/A"}</td>
                          <td>{data?.shopUrl ?? "N/A"}</td>
                          <td>{data?.sellerEmail ?? "N/A"}</td>
                          <td>{data?.mobile ?? "N/A"}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <div className="pagination">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  onPageChange={handlePageChange}
                  pageCount={Math.ceil(
                    allSellerEnquiries.length / itemsPerPage
                  )}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={3}
                  containerClassName={"pagination"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  disabledClassName={"disabled"}
                  activeClassName={"paging__link--active"}
                />
              </div>
            </div>
          </div> */}
          {/* <div className="container-fluid  p-3 m-3">
            <h4 className="head-h4 mb-3">Recently Listed Products</h4>
            <div className="card p-3 overflow">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price per Month</th>
                    <th scope="col">Price per Year</th>
                  </tr>
                </thead>
                <tbody>
                  {productList != null &&
                    Array.isArray(productList) &&
                    productList.map((product, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{product.productName}</td>
                          <td>£{product.price_monthly}</td>
                          <td>£{product.price_yearly}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div> */}
        </section>
      </div>
    </div>
  );
}

export default SuperAdmin;
