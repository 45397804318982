import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import Sidebar from "../../Components/Sidebar";
import { AppContext } from "../../Context/AppContext";
import Image1 from "../../Assets/Doc/49575-O63MXV.jpg";
import Slider from "react-slick";
import * as Ai from "react-icons/ai";
import * as Fa from "react-icons/fa";
import * as Lu from "react-icons/lu";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

function AddProposal() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const isSingle = searchParams.has("isSingle");
  const isDraft = searchParams.has("draft");
  const {
    templates,
    eventList,
    saveAsDraft,
    updateDraftDetails,
    fetchAllDrafts,
    draftLoading,
  } = useContext(AppContext);

  console.log("isDraft", isDraft);

  const navigate = useNavigate();
  const [selectedPackage, setSelectedPackage] = useState();
  const [elements, setElements] = useState([{ item: "", price: 0 }]);
  const [vatPercentage, setVatPercentage] = useState(20);
  const [showZerovalue, setShowZeroValue] = useState(false);
  const enquiryData = JSON.parse(sessionStorage.getItem("enquiryData")) ?? "";
  const [itemError, setItemError] = useState(false);
  const [notes, setNotes] = useState(null);
  const [services, setServices] = useState(null);
  const [serviceTotal, setServiceTotal] = useState(0);
  const [extraCharge, setExtraCharge] = useState(0);
  const [inputValues, setInputValues] = useState(() =>
    services && services.length > 0 ? services.map(() => []) : []
  );
  const [draftProposal, setDraftProposal] = useState(null);

  const [prodraftd, setProdraftd] = useState(0);
  const [totalPackage, setTotalPackage] = useState(0);
  const [inputErrors, setInputErrors] = useState([]);
  const [values, setValues] = useState({
    subTotal: 0,
    VAT: 0,
    VATAmount: 0,
    total: 0,
  });
  sessionStorage.removeItem("DirectProposal");
  sessionStorage.removeItem("selectedProduct");
  sessionStorage.removeItem("invoiceDetails");
  sessionStorage.removeItem("packageDetails");
  // sessionStorage.removeItem("draftProposalInfo");
  sessionStorage.removeItem("invoiceNumber");
  const [items, setItems] = useState({
    mininumof: "",
    guestRate: "",
    softDrinksRate: "",
    guestNumber: "",
    staffingCharges: "",
    staffingCount: "",
    beforeCompulsary: "",
    waitersCount: "",
    transportationCharges: "",
    rubbishDisposal: "",
    kitchenEquipment: "",
  });

  const [selectedProposal, setSelectedProposal] = useState(0);
  const [proposal, setProposal] = useState({
    currency: "£",
    email: enquiryData?.sellerDetails?.sellerEmail ?? "",
    clientName: enquiryData?.sellerDetails?.sellerName ?? "",
    phone: enquiryData?.sellerDetails?.mobile
      ? "+" + enquiryData?.sellerDetails?.mobile
      : "",
    shopName: "",
    address: enquiryData?.sellerDetails
      ? enquiryData?.sellerDetails?.address1 +
        " " +
        enquiryData?.sellerDetails?.address2 +
        " " +
        enquiryData?.sellerDetails?.town +
        " " +
        enquiryData?.sellerDetails?.postcode
      : "",
  });
  const [vatAMt, setVatAmt] = useState(0);
  const [serviceErrors, setServiceErrors] = useState([]);
  const [service, setService] = useState([]);
  const [isManualEdit, setIsManualEdit] = useState(false);
  const [leadIds, setLeadIds] = useState({
    pceId: "",
    leadId: "",
  });

  const handleTotalChange = (e) => {
    const value = Math.max(0, Number(e.target.value));
    setIsManualEdit(true); // Mark manual edit
    setTotalPackage(value);
  };

  useEffect(() => {
    const serviceTotal =
      (inputValues &&
        inputValues?.reduce((outerAcc, innerArray) => {
          const innerTotal = innerArray.reduce((innerAcc, item) => {
            const itemValue = parseFloat(item.value) || 0;
            return innerAcc + itemValue;
          }, 0);
          return outerAcc + innerTotal;
        }, 0)) ||
      0;

    const elementsTotal = elements.reduce((acc, element) => {
      const price = parseFloat(element.price) || 0;
      return acc + price;
    }, 0);

    // Subtotal calculation
    const subtotal = serviceTotal + elementsTotal;

    // VAT amount
    const vatAmount = (subtotal * vatPercentage) / 100;
    const total = subtotal + vatAmount;

    // Calculate extra charge and final total
    const cc = parseFloat(extraCharge) + parseFloat(serviceTotal);
    const tc = (cc * vatPercentage) / 100;
    const allTotalValue = cc + tc;

    // Update totalPackage only if it wasn't manually edited
    if (!isManualEdit) {
      setTotalPackage(allTotalValue);
    }

    // Update values in state
    setValues((prevValues) => ({
      ...prevValues,
      VAT: vatPercentage,
      VATAmount: tc,
      total: total,
      subTotal: subtotal,
      allTotal: cc,
    }));
  }, [inputValues, elements, vatPercentage, extraCharge, isManualEdit]);

  // Reset manual edit flag when input values or elements change
  useEffect(() => {
    setIsManualEdit(false);
  }, [inputValues, elements]);

  useEffect(() => {
    if (isDraft === true) {
      const proposalData = sessionStorage.getItem("draftProposal");
      const parsedProposal = proposalData ? JSON.parse(proposalData) : null;
      setDraftProposal(parsedProposal);

      if (parsedProposal) {
        setLeadIds({
          pceId: parsedProposal?.pceId ?? null,
          leadId: parsedProposal?.leadId ?? null,
        });
        setProdraftd(parsedProposal?.prodraftd);
        const elem = parsedProposal?.elements
          ? JSON.parse(parsedProposal?.elements)
          : null;
        const proposal = parsedProposal?.proposal
          ? JSON.parse(parsedProposal?.proposal)
          : null;
        const selectProposal =
          parsedProposal.selectedPackage &&
          typeof parsedProposal?.selectedPackage === "string"
            ? parseInt(parsedProposal?.selectedPackage)
            : parsedProposal?.selectedPackage;

        const serviceData = parsedProposal?.services
          ? JSON.parse(parsedProposal?.services)
          : null;
        const items = parsedProposal?.items
          ? JSON.parse(parsedProposal?.items)
          : null;

        const newItems =
          serviceData &&
          serviceData.length != 0 &&
          serviceData.map((course) => course.items);

        const alteredData =
          serviceData &&
          serviceData.length != 0 &&
          serviceData.map((item) => {
            const totalValue = item?.items.reduce(
              (sum, item) => sum + Number(item.value),
              0
            );

            return {
              ...item,
              value: "",
              head: "",
              total: totalValue,
            };
          });

        setService(alteredData);
        setInputValues(newItems);
        setElements(elem);
        setProposal(proposal);
        setSelectedPackage(selectProposal);
        setServices(serviceData);
        setShowZeroValue(parsedProposal?.showZerovalue);
        setSelectedProposal(parsedProposal?.tempId);
        setItems(items);
        setNotes(parsedProposal?.notes);
      }
    }
  }, [location]);

  useEffect(() => {
    if (
      !services ||
      (services && !Array.isArray(services)) ||
      (services && services.length == 0)
    )
      return;

    if (
      isDraft === true &&
      selectedPackage == (draftProposal && draftProposal?.selectedPackage)
    )
      return;

    setService(
      services.map((service) => ({
        courseHead: service?.courseHead ?? "N/A",
        value: "",
        head: "",
        description: service?.description ?? "N/A",
        total: 0,
      }))
    );
    setServiceErrors(services.map(() => false));
    setInputValues(
      services.map((service) => [
        {
          courseHead: service?.courseHead ?? "N/A",
          value: "",
          head: "",
          description: service?.description ?? "N/A",
          total: 0,
        },
      ])
    );
  }, [services, draftProposal]);

  useEffect(() => {
    const totalServiceCharges =
      service &&
      service.reduce((acc, data) => {
        const totalValue = Number(data.total);
        return acc + (isNaN(totalValue) ? 0 : totalValue);
      }, 0);

    setServiceTotal(totalServiceCharges);
  }, [service, extraCharge]);

  const handleIncrease = (index) => {
    setInputValues((prevValues) => {
      const newValues = prevValues.map((value) => [...value]);
      if (!Array.isArray(newValues[index])) {
        newValues[index] = [];
      }
      newValues[index].push({
        head: "",
        value: "",
        description: service[index].description,
        courseHead: service[index].courseHead,
        total: 0,
      });
      return newValues;
    });
  };

  const handleIncreasedInput = (e, serviceIndex, inputIndex) => {
    const { name, value } = e.target;

    let validatedValue = value;

    if (name === "value") {
      validatedValue = value.replace(/[^0-9.]/g, "");
      const decimalIndex = validatedValue.indexOf(".");
      if (decimalIndex !== -1) {
        validatedValue =
          validatedValue.slice(0, decimalIndex + 1) +
          validatedValue.slice(decimalIndex + 1).replace(/\./g, "");
      }
    }

    // Update the input value
    setInputValues((prevValues) => {
      const newValues = [...prevValues];

      if (Array.isArray(newValues[serviceIndex])) {
        newValues[serviceIndex][inputIndex] = {
          ...newValues[serviceIndex][inputIndex],
          [name]: validatedValue,
        };
        setService((prevServices) => {
          const updatedServices = [...prevServices];
          const serviceItem = updatedServices[serviceIndex];
          if (!serviceItem.items) {
            serviceItem.items = [];
          }

          if (inputIndex < serviceItem.items.length) {
            serviceItem.items[inputIndex] = {
              head: newValues[serviceIndex][inputIndex].head,
              value: newValues[serviceIndex][inputIndex].value,
            };
          } else {
            serviceItem.items.push({
              head: newValues[serviceIndex][inputIndex].head,
              value: newValues[serviceIndex][inputIndex].value,
            });
          }

          const total = serviceItem.items.reduce((acc, input) => {
            const inputValue = parseFloat(input.value) || 0;
            return acc + inputValue;
          }, 0);

          serviceItem.total = total;

          return updatedServices;
        });
      }

      return newValues;
    });
  };
  const handleService = (e, index) => {
    const { name, value } = e.target;
    setService((prevItems) =>
      prevItems.map((item, idx) =>
        idx === index ? { ...item, [name]: value } : item
      )
    );

    setServiceErrors((prevErrors) =>
      prevErrors.map((error, idx) => (idx === index ? false : error))
    );
  };

  const handlePercentageValue = (e) => {
    const value = Math.max(0, Number(e.target.value));
    setVatPercentage(value);
  };
  const handleToggle = () => {
    setShowZeroValue(!showZerovalue);
  };
  useEffect(() => {
    console.log("toggle", showZerovalue);
  }, [showZerovalue]);

  console.log(selectedPackage, "eleceed");
  const handleSubTotalChange = (e) => {
    const value = Math.max(0, Number(e.target.value));
    setValues((prevValues) => ({
      ...prevValues,
      subTotal: value,
    }));
  };

  const validateServices = () => {
    let hasError = false;

    const updatedErrors = service.map((item) => {
      if (item.value.trim() === "") {
        hasError = true;
        return true;
      }
      return false;
    });

    setServiceErrors(updatedErrors);
    return hasError;
  };
  const handleTemp = (temp) => {
    setSelectedProposal(temp.template_id);
  };
  useEffect(() => {
    if (selectedPackage != null && selectedPackage != "") {
      const serviceData =
        eventList && eventList.find((x) => x?.packId == selectedPackage);

      if (serviceData != null || serviceData != undefined) {
        setServices(JSON.parse(serviceData?.courses));
      }
    }
  }, [selectedPackage]);

  const handleSelectProposal = (e) => {
    setSelectedPackage(e.target.value);
    setInputValues(
      services &&
        services.map((service) => [
          {
            courseHead: service?.courseHead ?? "N/A",
            value: "",
            head: "",
            description: service?.description ?? "N/A",
            total: 0,
          },
        ])
    );
  };
  const formedArray = [
    {
      description:
        "Minimum of " +
        items?.mininumof +
        " guests @ £" +
        items?.guestRate +
        " per head",
      price: items?.mininumof * items?.guestRate,
    },
    {
      description:
        "Soft Drinks Package @ £ " +
        items?.softDrinksRate +
        " per head for " +
        items?.guestNumber +
        " guests",
      price: items?.softDrinksRate * items?.guestNumber,
    },
    {
      description:
        "Staffing charges @ £" +
        items?.staffingCharges +
        ". per head for" +
        items?.staffingCount +
        " staffs",
      price: items?.staffingCharges * items?.staffingCount,
    },
    {
      description:
        items?.beforeCompulsary +
        " Compulsory Drink Waiters @" +
        items?.waitersCount +
        ".per head",
      price: items?.beforeCompulsary * items?.waitersCount,
    },
    {
      description: "Transportation Charges",
      price: items?.transportationCharges,
    },
    { description: "Rubbish Disposal", price: items?.rubbishDisposal },
    { description: "Kitchen Equipment Hire", price: items?.kitchenEquipment },
  ];
  const handleAddItem = () => {
    const lastItem = elements[elements.length - 1];
    if (lastItem != null) {
      if (lastItem.item.trim() === "" || lastItem.price.trim() === "") {
        setItemError(true);
        return;
      }
    }

    setItemError(false);
    setElements([...elements, { item: "", price: "" }]);
  };
  const eventUpdation = useCallback(() => {
    // Calculate event-specific totals
    const eventTotal =
      (parseFloat(items?.mininumof) || 0) *
        (parseFloat(items?.guestRate) || 0) +
      (parseFloat(items?.softDrinksRate) || 0) *
        (parseFloat(items?.guestNumber) || 0) +
      (parseFloat(items?.beforeCompulsary) || 0) *
        (parseFloat(items?.waitersCount) || 0) +
      (parseFloat(items?.staffingCharges) || 0) *
        (parseFloat(items?.staffingCount) || 0) +
      (parseFloat(items?.kitchenEquipment) || 0) +
      (parseFloat(items?.rubbishDisposal) || 0) +
      (parseFloat(items?.transportationCharges) || 0);

    const elementsTotal = elements.reduce((acc, item) => {
      let price =
        typeof item?.price === "string" ? parseFloat(item?.price) : item?.price;
      return acc + (price || 0);
    }, 0);
    const serviceTotal =
      service &&
      service.reduce((acc, serviceItem) => {
        let value = parseFloat(serviceItem?.value) || 0;
        return acc + value;
      }, 0);

    const inTotal = eventTotal + elementsTotal + serviceTotal;

    const taxTotal = (inTotal * (parseFloat(vatPercentage) || 0)) / 100;

    const allTotal = inTotal + taxTotal;
    setExtraCharge(inTotal.toFixed(2));
    setValues((prevValues) => ({
      ...prevValues,
      subTotal: parseFloat(inTotal.toFixed(2)),
      taxAmount: parseFloat(taxTotal.toFixed(2)),
      VAT: parseFloat(vatPercentage) || 0,
      total: parseFloat(allTotal.toFixed(2)),
    }));
  }, [items, elements, service, vatPercentage]);

  const calculateTaxAmount = (subTotal, vatPercentage) => {
    return parseFloat(subTotal) * (parseFloat(vatPercentage) / 100);
  };

  useEffect(() => {
    if (!values) return;

    setVatAmt(values.taxAmount);
  }, [vatPercentage, values]);

  useEffect(() => {
    eventUpdation();
  }, [eventUpdation]);

  const handleDeleteItem = (index) => {
    const newElements = [...elements];
    newElements.splice(index, 1);
    setElements(newElements);
  };

  const getTotalTaxAmount = (pro) => {
    const amount = pro.quantity * pro.price;
    const taxAmount = (amount * pro.tax) / 100;
    return taxAmount;
  };

  const handleInputChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or event.target is undefined");
      return;
    }
    const { name, value } = e.target;

    const regex = /^\d*\.?\d*$/;
    if (regex.test(value)) {
      setItems((prevItems) => ({
        ...prevItems,
        [name]: value || 0,
      }));
    }
  };
  const handleElementUpdate = (index, e) => {
    const { name, value } = e.target;
    const newElements = [...elements];

    if (name === "price") {
      const numericValue = value.replace(/[^0-9£.]/g, "");

      setItemError(false);
      newElements[index] = {
        ...newElements[index],
        [name]: numericValue.startsWith("") ? numericValue : "" + numericValue,
      };
    } else {
      setItemError(false);
      newElements[index] = { ...newElements[index], [name]: value };
    }

    setElements(newElements);
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const onProposalChange = (e) => {
    const { name, value } = e.target;
    setProposal((prev) => {
      return { ...prev, [name]: value };
    });
  };

  // const phonenumberValidation = (e) => {
  //   const value = e.target.value;
  //   let phoneNum = value.replace(/[^\d()+]/g, "");

  //   setProposal((prev) => ({ ...prev, phone: phoneNum }));
  // };
  const phonenumberValidation = (e) => {
    const value = e.target.value;
    let phoneNum = value.replace(/[^\d]/g, "");
    if (phoneNum.length > 10) {
      phoneNum = phoneNum.slice(0, 10);
    }
    setProposal((prev) => ({ ...prev, phone: phoneNum }));
  };

  const validateProposal = () => {
    if (proposal?.phone == undefined) {
      toast.error("Phone number Required!.");
      return true;
    }
    if (proposal.phone.length < 10) {
      toast.error("Please check phone number!");
      return;
    }
    validateServices();
    if (
      !proposal?.clientName ||
      proposal.clientName.trim() === "" ||
      !proposal?.phone ||
      proposal.phone.trim() === "" ||
      !proposal?.email ||
      proposal.email.trim() === ""
    ) {
      toast.error("Please Fill All Client Details!.");
      return true;
    }

    if (selectedProposal === 0) {
      toast.error("Please select a Proposal Template!");
      return true;
    }
    const hasEmptyItem = elements.some((elem) => elem.item.length === 0);
    if (hasEmptyItem) {
      if (elements[0].item.length != 0 || elements[0].price.length != 0) {
        return false;
      }
      toast.error("Please make sure the fields are not empty!");
      return true;
    }

    return false;
  };
  const handleZero = () => {
    if (showZerovalue == false) {
      alert("off");
    } else {
      alert("on");
    }
  };
  console.log(services, "services");
  const handleProposal = () => {
    const isValidate = validateProposal();
    const pceId = searchParams.get("pceId") ?? null;
    const leadId = searchParams.get("leadId") ?? null;
    const combinedServices =
      services &&
      services.map((service) => {
        const matchedItems = inputValues.find(
          (inputs) => inputs[0]?.courseHead === service?.courseHead
        );

        return {
          ...service,
          items: matchedItems ? [...matchedItems] : [],
        };
      });
    // handleZero();
    if (isValidate === false) {
      const selectedPackData = selectedPackage == 1 ? formedArray : [];
      const proposalData = {
        pceId,
        leadId,
        formedArray,
        proposal,
        tempId: selectedProposal,
        elements,
        values,
        selectedPackage,
        service: combinedServices, //! changes happends here
        totalPackage: totalPackage,
        showZerovalue: showZerovalue,
        notes: notes,
      };
      const draftProposalInfo = {
        pceId,
        leadId,
        name: proposal?.clientName,
        email: proposal?.email,
        items: items && JSON.stringify(items),
        proposal: proposal && JSON.stringify(proposal),
        tempId:
          selectedProposal && typeof selectedProposal == "string"
            ? parseInt(selectedProposal)
            : selectedProposal,
        elements: elements && JSON.stringify(elements),
        price_values: values && JSON.stringify(values),
        selectedPackage:
          selectedPackage && typeof selectedPackage == "string"
            ? parseInt(selectedPackage)
            : selectedPackage,
        services: combinedServices && JSON.stringify(combinedServices),
        totalPackage: totalPackage && JSON.stringify(totalPackage),
        showZerovalue: showZerovalue,
        notes: notes,
        currency: "£",
      };

      if (selectedProposal == 0) {
        toast.error("Please select a Proposal Template!");
        return;
      }

      // setProdraftd(0);
      // sessionStorage.removeItem("prodraftd");

      if (isSingle === true) {
        sessionStorage.setItem("proposalData", JSON.stringify(proposalData));

        if (selectedProposal == 1) {
          sessionStorage.setItem(
            "draftProposalInfo",
            JSON.stringify(draftProposalInfo)
          );
          navigate(
            `/template/${selectedProposal}?single${
              isDraft ? `&&draftId=${prodraftd}` : ""
            }`
          );
        }
        if (selectedProposal == 2) {
          sessionStorage.setItem(
            "draftProposalInfo",
            JSON.stringify(draftProposalInfo)
          );
          navigate(
            `/template2/${selectedProposal}?single${
              isDraft ? `&&draftId=${prodraftd}` : ""
            }`
          );
        }
      } else {
        sessionStorage.setItem("proposalData", JSON.stringify(proposalData));

        if (selectedProposal == 1) {
          sessionStorage.setItem(
            "draftProposalInfo",
            JSON.stringify(draftProposalInfo)
          );
          navigate(
            `/template/${selectedProposal}?${
              isDraft ? `&&draftId=${prodraftd}` : ""
            }`
          );

          // navigate(`/template/${selectedProposal}`);
        }
        if (selectedProposal == 2) {
          sessionStorage.setItem(
            "draftProposalInfo",
            JSON.stringify(draftProposalInfo)
          );
          navigate(
            `/template2/${selectedProposal}?${
              isDraft ? `&&draftId=${prodraftd}` : ""
            }`
          );

          // navigate(`/template2/${selectedProposal}`);
        }
      }
    }
  };

  const handleDeleteSingleMealItem = (index, inputIndex) => {
    const updatedInputValues = [...inputValues];
    if (updatedInputValues[index]) {
      updatedInputValues[index].splice(inputIndex, 1);

      setInputValues(updatedInputValues);

      const newTotal = updatedInputValues.reduce((outerAcc, innerArray) => {
        if (Array.isArray(innerArray)) {
          const innerTotal = innerArray.reduce((innerAcc, item) => {
            const itemValue = parseFloat(item.value) || 0;
            return innerAcc + itemValue;
          }, 0);
          return outerAcc + innerTotal;
        }
        return outerAcc;
      }, 0);

      setServiceTotal(newTotal);
    }
  };

  const handleDraftData = async () => {
    if (
      !proposal?.clientName ||
      proposal.clientName.trim() === "" ||
      !proposal?.phone ||
      proposal.phone.trim() === "" ||
      !proposal?.email ||
      proposal.email.trim() === ""
    ) {
      toast.error("Please Fill All mandatory fields!.");
      return;
    }
    const pceId = searchParams.get("pceId") ?? null;
    const leadId = searchParams.get("leadId") ?? null;
    const combinedServices =
      services &&
      services.map((service) => {
        const matchedItems = inputValues.find(
          (inputs) => inputs[0].courseHead === service.courseHead
        );

        return {
          ...service,
          items: matchedItems ? [...matchedItems] : [],
        };
      });
    const proposalData = {
      pceId,
      leadId,
      name: proposal?.clientName,
      email: proposal?.email,
      items: items && JSON.stringify(items),
      proposal: proposal && JSON.stringify(proposal),
      tempId:
        selectedProposal && typeof selectedProposal == "string"
          ? parseInt(selectedProposal)
          : selectedProposal,
      elements: elements && JSON.stringify(elements),
      price_values: values && JSON.stringify(values),
      selectedPackage:
        selectedPackage && typeof selectedPackage == "string"
          ? parseInt(selectedPackage)
          : selectedPackage,
      services: combinedServices && JSON.stringify(combinedServices),
      totalPackage: totalPackage && JSON.stringify(totalPackage),
      showZerovalue: showZerovalue,
      notes: notes,
      currency: "£",
    };
    console.log(proposalData, "proposal data");
    if (prodraftd == 0) {
      await saveAsDraft(proposalData, {
        onSuccess: async (res) => {
          setProdraftd(res?.data?.insertId);
          sessionStorage.setItem("prodraftd", res?.data?.insertId);
          await fetchAllDrafts();
          toast.success("Your proposal has been saved!");
        },
        onFailed: (err) => {
          toast.error("Error on saving proposal");
        },
      });
    } else if (prodraftd != 0) {
      const payload = {
        prodraftd,
        ...proposalData,
      };

      await updateDraftDetails(payload, {
        onSuccess: async (res) => {
          await fetchAllDrafts();
          toast.success("Your proposal has been updated!");
        },
        onFailed: (err) => {
          toast.error("Error on saving proposal");
        },
      });
    } else {
      toast.error("Draft saving failed!");
    }
  };

  return (
    <div className="container-fluid">
      <Sidebar>
        <Toaster position="top-center" />
        <div className="col-md-8 mx-auto">
          <div className="slider-wrapper mt-3 mb-3">
            <h6 className="sub_title">Choose Template</h6>
            <Slider {...settings}>
              {templates != null &&
                templates.map((temp) => (
                  <div
                    className={
                      selectedProposal !== temp.template_id
                        ? "image-slide"
                        : "image-slide selected"
                    }
                    key={temp.template_id}
                    onClick={() => handleTemp(temp)}
                  >
                    <img
                      src={
                        temp?.image != null
                          ? process.env.REACT_APP_AWS_IMAGE_URL +
                            "proposal-templates/" +
                            temp?.image
                          : Image1
                      }
                      className={`slider ${
                        temp.template_id === temp.image ? "selected" : ""
                      }`}
                      alt={"Template Image"}
                    />
                  </div>
                ))}
            </Slider>
          </div>
          <hr className="line_dotted" />
          <div className="card p-3">
            <div style={{ float: "right", textAlign: "right" }}>
              <div id="cb-label">
                Show Zero Values
                <span id="toggle-state"> {showZerovalue ? "On" : "Off"}</span>
              </div>
              <input
                id="cb-toggle"
                type="checkbox"
                class="hide-me"
                aria-labelledby="cb-label"
                name="showZeroValue"
                checked={showZerovalue}
                onClick={() => handleToggle()}
              />
              <label for="cb-toggle" class="toggle"></label>
            </div>
            <div className="form-group mb-2" style={{ width: "50%" }}>
              <label htmlFor="" className="form-label">
                <strong>Packages</strong>
              </label>
              <select
                name=""
                id=""
                className="form-select mb-3"
                value={selectedPackage}
                onChange={handleSelectProposal}
              >
                <option value="" disabled selected>
                  Select package
                </option>

                {eventList.length != 0 &&
                  eventList.map((data, index) => {
                    return (
                      <>
                        {data.disabled != true && (
                          <Fragment>
                            <option value={data?.packId} key={index}>
                              {data?.title} - {data?.no_of_courses ?? 0} course
                              menu
                            </option>
                          </Fragment>
                        )}
                      </>
                    );
                  })}
              </select>
            </div>
            <form className="card new_card ">
              <b>Package Details is as follows:</b>

              <div className="">
                <table className="table table-bordered">
                  <tbody>
                    {services != null && services.length !== 0 && (
                      <>
                        {service &&
                          service.map((data, index) => {
                            console.log("reached service", data);

                            const total = inputValues[index]?.reduce(
                              (acc, input) => {
                                const value = parseFloat(input.value) || 0;
                                return acc + value;
                              },
                              0
                            );

                            const subTotalValue = inputValues.reduce(
                              (outerAcc, innerArray) => {
                                const innerTotal = innerArray.reduce(
                                  (innerAcc, item) => {
                                    const itemValue =
                                      parseFloat(item.value) || 0;
                                    return innerAcc + itemValue;
                                  },
                                  0
                                );
                                return outerAcc + innerTotal;
                              },
                              0
                            );
                            console.log("inppp", inputValues);

                            return (
                              <Fragment>
                                <tr key={index}>
                                  <td>
                                    <button
                                      className="add-course-"
                                      type="button"
                                      onClick={() => handleIncrease(index)}
                                    >
                                      +
                                    </button>
                                    <strong>{data.courseHead}</strong>
                                    {data &&
                                      data.description &&
                                      data.description != "N/A" &&
                                      `- ${data.description}`}

                                    {inputValues[index] &&
                                    inputValues[index].length > 0 ? (
                                      <div className="row mt-2">
                                        {inputValues[index].map(
                                          (input, inputIndex) => {
                                            console.log("inpp", input);

                                            return (
                                              <Fragment>
                                                <div
                                                  key={inputIndex}
                                                  className="mt-2"
                                                >
                                                  {/* <label
                                                style={{
                                                  textAlign: "left",
                                                }}
                                              >
                                                Item #
                                              </label> */}
                                                  <div
                                                    className="row"
                                                    style={{ marginTop: "3px" }}
                                                  >
                                                    <div className="col-lg-9 col-md-9 col-sm-12">
                                                      <input
                                                        type="text"
                                                        name="head"
                                                        className="form-control"
                                                        value={input.head}
                                                        placeholder={`item ${
                                                          inputIndex + 1
                                                        }`}
                                                        onChange={(e) =>
                                                          handleIncreasedInput(
                                                            e,
                                                            index,
                                                            inputIndex
                                                          )
                                                        }
                                                      />
                                                      {inputErrors[index] &&
                                                        inputErrors[index][
                                                          inputIndex
                                                        ] && (
                                                          <p
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            {
                                                              inputErrors[
                                                                index
                                                              ][inputIndex]
                                                            }
                                                          </p>
                                                        )}
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-sm-12">
                                                      <input
                                                        type="text"
                                                        name="value"
                                                        placeholder="£"
                                                        className="form-control"
                                                        value={input.value}
                                                        onChange={(e) =>
                                                          handleIncreasedInput(
                                                            e,
                                                            index,
                                                            inputIndex
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                    <div className="col-lg-1 col-md-1 col-sm-12">
                                                      {input.head.length !=
                                                        0 && (
                                                        <button
                                                          type="button"
                                                          className="menu_dlt_btn_"
                                                          onClick={() =>
                                                            handleDeleteSingleMealItem(
                                                              index,
                                                              inputIndex
                                                            )
                                                          }
                                                        >
                                                          <Fa.FaRegTrashAlt />
                                                        </button>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </Fragment>
                                            );
                                          }
                                        )}
                                      </div>
                                    ) : null}
                                  </td>
                                  {/* <td>
                              <input
                                type="text"
                                placeholder="£"
                                className="item_input"
                                name="value"
                                value={data.value || ""}
                                onChange={(e) => handleService(e, index)}
                              />
                              {serviceErrors[index] && (
                                <p style={{ color: "red" }}>
                                  This field is required.
                                </p>
                              )}
                            </td> */}
                                  <td>£{total?.toFixed(2) || "0"}</td>
                                </tr>
                                {/* <tr>
                                  <td>
                                    <strong>Sub total of courses:</strong>
                                  </td>
                                  <td>
                                    {subTotalValue
                                      ? subTotalValue.toFixed(2)
                                      : 0}
                                  </td>
                                </tr> */}
                              </Fragment>
                            );
                          })}
                      </>
                    )}
                    <tr>
                      <td>
                        <b>Total of course items</b>
                      </td>
                      <td>
                        {serviceTotal ? `£ ${serviceTotal.toFixed(2)}` : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <label>Notes</label>
                        <textarea
                          className="form-control mt-1"
                          value={notes}
                          name="notes"
                          onChange={(e) => setNotes(e.target.value)}
                        />
                      </td>
                    </tr>
                    {/* {selectedPackage && (
                      <> */}
                    <tr>
                      <td>
                        Minimum of{" "}
                        <input
                          className="normal_field"
                          value={items.mininumof}
                          name="mininumof"
                          onChange={(e) => handleInputChange(e)}
                        />
                        @{" "}
                        <input
                          className="normal_field"
                          value={items.guestRate}
                          name="guestRate"
                          onChange={(e) => handleInputChange(e)}
                        />{" "}
                        per head
                      </td>
                      <td>
                        {/* <input
                                type="text"
                                name="price"
                                className="item_input"
                                value={
                                  "£" + items?.mininumof * items?.guestRate
                                }
                              /> */}
                        <p>
                          {"£" + items?.mininumof * items?.guestRate ?? "N/A"}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Soft Drinks Package @ £{" "}
                        <input
                          className="normal_field"
                          name="softDrinksRate"
                          value={items.softDrinksRate}
                          onChange={(e) => handleInputChange(e)}
                        />
                        per head for
                        <input
                          className="normal_field"
                          name="guestNumber"
                          value={items.guestNumber}
                          onChange={(e) => handleInputChange(e)}
                        />
                        guests.
                      </td>
                      <td>
                        {"£" + items?.softDrinksRate * items?.guestNumber ??
                          "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Staffing charges @ £{" "}
                        <input
                          className="normal_field"
                          name="staffingCharges"
                          value={items.staffingCharges}
                          onChange={(e) => handleInputChange(e)}
                        />
                        per head for
                        <input
                          className="normal_field"
                          placeholder="..."
                          name="staffingCount"
                          value={items.staffingCount}
                          onChange={(e) => handleInputChange(e)}
                        />
                        Staff.
                      </td>
                      <td>
                        <p>
                          {"£" +
                            items?.staffingCharges * items?.staffingCount ??
                            "N/A"}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          className="normal_field"
                          placeholder="..."
                          name="beforeCompulsary"
                          value={items.beforeCompulsary}
                          onChange={(e) => handleInputChange(e)}
                        />
                        Compulsory Drink Waiters @{" "}
                        <input
                          className="normal_field"
                          name="waitersCount"
                          value={items.waitersCount}
                          onChange={(e) => handleInputChange(e)}
                        />
                        per head
                      </td>
                      <td>
                        <p>
                          {" "}
                          {"£" +
                            items?.beforeCompulsary * items?.waitersCount ??
                            "N/A"}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>Transportation Charges</td>
                      <td>
                        <input
                          type="text"
                          placeholder="£"
                          className="item_input"
                          name="transportationCharges"
                          value={items.transportationCharges}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Rubbish Disposal</td>
                      <td>
                        <input
                          type="text"
                          placeholder="£"
                          className="item_input"
                          name="rubbishDisposal"
                          value={items.rubbishDisposal}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Kitchen Equipment Hire</td>
                      <td>
                        <input
                          type="text"
                          placeholder="£"
                          className="item_input"
                          name="kitchenEquipment"
                          value={items.kitchenEquipment}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </td>
                    </tr>
                    {/* </>
                    )} */}
                    {elements.map((element, index) => (
                      <tr key={index}>
                        <td style={{ width: "80%" }}>
                          <div className="d-flex">
                            {index === elements.length - 1 && (
                              <button
                                type="button"
                                onClick={handleAddItem}
                                style={{ border: "none", background: "#fff" }}
                              >
                                <Ai.AiOutlinePlus className="iconsbtn" />
                              </button>
                            )}
                            <textarea
                              rows={1}
                              name="item"
                              placeholder="..."
                              value={element.item}
                              className="item_input"
                              onChange={(e) => handleElementUpdate(index, e)}
                              style={{ resize: "none" }}
                            />
                          </div>
                        </td>
                        <td style={{ width: "20%" }}>
                          <div className="d-flex">
                            <input
                              type="text"
                              name="price"
                              placeholder="£"
                              value={element.price}
                              className="item_input"
                              onChange={(e) => handleElementUpdate(index, e)}
                              disabled={elements[index].item.length === 0}
                            />
                            {elements.length > 1 && (
                              <button
                                type="button"
                                className="delete_btn_item"
                                onClick={() => handleDeleteItem(index)}
                              >
                                <Ai.AiOutlineDelete />
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}

                    {itemError && (
                      <tr>
                        <td colSpan="2">
                          <p style={{ color: "red" }}>
                            Please fill in all fields before adding a new item.
                          </p>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td>
                        <b>Sub Total of Extra Charges:</b>
                      </td>
                      <td>
                        {/* <input
                          type="text"
                          value={values.subTotal}
                          style={{
                            width: "100%",
                            border: "none",
                            background: "transparent",
                          }}
                          onChange={handleSubTotalChange}
                          min={0}
                        /> */}
                        {extraCharge ?? 0}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Total Cost</b>
                      </td>
                      <td>
                        {parseFloat(extraCharge) + parseFloat(serviceTotal)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        VAT %{" "}
                        <input
                          className="normal_field p-1"
                          type="number"
                          name="vatPercentage"
                          min={0}
                          value={vatPercentage}
                          onChange={handlePercentageValue}
                        />
                      </td>
                      <td>
                        {/* <p>£{values.VATAmount.toFixed(2)}</p> */}£
                        {((parseFloat(extraCharge) + parseFloat(serviceTotal)) *
                          vatPercentage) /
                          100}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Total Package Price:</b>
                      </td>
                      <td>
                        <input
                          type="number"
                          value={totalPackage ? totalPackage.toFixed(2) : 0}
                          style={{
                            width: "100%",
                            border: "none",
                            background: "transparent",
                          }}
                          name="totalPackage"
                          onChange={handleTotalChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr className="line_dotted" />
              <h6 className="sub_title">Client Details</h6>
              <div className="row p-2">
                {" "}
                <div className="col-xl-6 col-md-6 col-sm-12 mb-2">
                  <label>
                    Client's Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="clientName"
                    min={0}
                    value={proposal?.clientName}
                    onChange={(e) => onProposalChange(e)}
                  />
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12 mb-2">
                  <label>
                    Phone<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="tel"
                    maxLength={10}
                    name="phone"
                    pattern="^\d{0,10}$"
                    // disabled
                    value={proposal?.phone ?? ""}
                    onChange={(e) => phonenumberValidation(e)}
                  />
                </div>
                <div className="col-xl-12 col-md-12 col-sm-12">
                  <label>
                    Email<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="email"
                    min={0}
                    value={proposal?.email}
                    onChange={(e) => onProposalChange(e)}
                  />
                </div>
              </div>
              <div className="row p-2">
                {" "}
                <div className="col-md-12 col-sm-12 ">
                  <label>Address</label>
                  <br />
                  <textarea
                    className="des_text p-2"
                    name="address"
                    value={proposal?.address ?? ""}
                    onChange={onProposalChange}
                  ></textarea>
                </div>
              </div>
              <div className="btn-grp">
                <button
                  type="button"
                  className="continue_btn mb-3 mt-3"
                  onClick={handleProposal}
                >
                  Continue{" "}
                  <i>
                    <Ai.AiOutlineArrowRight />
                  </i>
                </button>
                <button
                  type="button"
                  className="save_as_draft mb-3 mt-3"
                  onClick={handleDraftData}
                  disabled={draftLoading}
                >
                  {!draftLoading ? (
                    <Fragment>
                      <i className="pe-2">
                        <Lu.LuArchive />
                      </i>
                      <span>Save as Draft</span>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div
                        class="spinner-border spinner-border-sm text-success"
                        role="status"
                      ></div>{" "}
                      <span>Please Wait</span>
                    </Fragment>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default AddProposal;
