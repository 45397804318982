import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import { Modal, Select, message } from "antd";
import { toast, Toaster } from "react-hot-toast";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import * as Bs from "react-icons/bs";
import EditInvoice from "../../Pages/Leads/EditInvoice";
import { elements } from "chart.js";
function Invoicing() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    generateInvoice,
    balanceDue,
    setBalanceDue,
    invoiceNumber,
    setInvoiceNumber,
    singleInvoiceGeneration,
  } = useContext(AppContext);
  const invoiceData = JSON.parse(sessionStorage.getItem("selectedProduct"));

  // const accountinfo = invoiceData.bankDetails;
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isExport, setIsExport] = useState(false);
  const [packageDetails, setPackageDetails] = useState(null);
  const [servicesTotal, setServicesTotal] = useState(0);
  const [vatAmount, setVatAmount] = useState(0);
  const [itemsTotal, setItemsTotal] = useState(0);
  const [editInvoice, setEditInvoice] = useState({
    username: invoiceData?.customersDetails?.clientName,
    email: invoiceData?.customersDetails?.email,
    billingAddress: invoiceData?.customersDetails?.address,
    dueDate: null,
    paymentAccount: "123456",
    branch: "Trivandrum",
    method: "other",
    bank: "State Bank of India",
    ifsc: "SBI56142541",
    price: invoiceData?.dealData?.price,
    subscription: invoiceData?.dealData?.subscription,
    sortCode: "SORTCODE",
    swifCode: "SWIFTCODE",
    discount: 0,
    deposit: 0,
    message: "",
    productDescription: "",
  });

  useEffect(() => {
    if (!invoiceData) return;

    const packageData = invoiceData?.productDetails;
    setPackageDetails(packageData);
  }, []);

  useEffect(() => {
    if (editInvoice?.discount == 0) {
      setVatAmount(invoiceData?.productDetails?.values?.VATAmount ?? 0);
    } else {
      const vatamount =
        ((packageDetails?.values?.allTotal - editInvoice?.discount) *
          invoiceData?.productDetails?.values?.VAT) /
        100;
      setVatAmount(vatamount);
    }
  }, [editInvoice]);

  useEffect(() => {
    if (!packageDetails) return;

    let totalValue = 0;
    if (Array.isArray(packageDetails.services)) {
      packageDetails.services.forEach((service) => {
        if (Array.isArray(service.items)) {
          service.items.forEach((item) => {
            const itemValue = parseFloat(item.value) || 0;
            totalValue += itemValue;
          });
        }
      });
    }

    let itemsTotalVal = 0;
    let valuesTotal = 0;
    if (Array.isArray(packageDetails.itemsArray)) {
      packageDetails.itemsArray.forEach((item) => {
        const itemPrice = parseFloat(item.price) || 0;
        itemsTotalVal += itemPrice;
      });
    }
    if (Array.isArray(packageDetails.elementArray)) {
      packageDetails.elementArray.forEach((item) => {
        const itemPrice = parseFloat(item.price) || 0;
        valuesTotal += itemPrice;
      });
    }

    setItemsTotal(parseFloat(itemsTotalVal) + parseFloat(valuesTotal));
    setServicesTotal(totalValue);
  }, [packageDetails]);

  const handleMethod = (e) => {
    setEditInvoice((prevEditDeals) => ({
      ...prevEditDeals,
      method: e.target.value,
    }));
  };
  const handleDiscount = (e) => {
    setEditInvoice((prev) => ({
      ...prev,
      discount: e.target.value,
    }));
  };
  const handlePriceChange = (e) => {
    setEditInvoice((prev) => ({
      ...prev,
      price: e.target.value,
    }));
  };
  const handleDepositChange = (e) => {
    setEditInvoice((prev) => ({
      ...prev,
      deposit: e.target.value,
    }));
  };
  const handleSub = (e) => {
    setEditInvoice((prevEditDeals) => ({
      ...prevEditDeals,
      subscription: e.target.value,
    }));
  };
  useEffect(() => {
    setEditInvoice((prev) => ({
      ...prev,
      invoiceDate: getCurrentDate(),
    }));
  }, []);

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setEditInvoice((prev) => {
      const isDateInput = e.target.type === "date";
      const formattedValue = isDateInput ? e.target.value : value;
      return { ...prev, [name]: formattedValue };
    });
  };

  const onChnageInfo = (e) => {
    const { name, value } = e.target;
    setEditInvoice((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  console.log("packageDetails", packageDetails);
  const onChangeDescription = (e) => {
    const { name, value } = e.target;
    if (value.length <= 70) {
      setEditInvoice({
        ...editInvoice,
        [name]: value,
      });
    }
  };
  // const onChangeDescription = (e) => {
  //   const { name, value } = e.target;
  //   setEditInvoice((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };
  const getCurrentDate = () => {
    const currentDate = new Date();

    const formattedDate = currentDate.toISOString().split("T")[0];
    return formattedDate;
  };
  const contractDetails = JSON.parse(
    sessionStorage.getItem("selectedContract")
  );
  useEffect(() => {
    if (invoiceData) {
      const beforeAdvance =
        packageDetails?.values?.allTotal + vatAmount - editInvoice.discount;

      setTotalAmount(beforeAdvance);
    }

    if (invoiceData) {
      const beforeAdvance = totalAmount - editInvoice.deposit;
      console.log(typeof beforeAdvance, "beforeAdvance");
      const updated = beforeAdvance.toFixed(2);
      setBalanceDue(updated);
    }
  }, [invoiceData, editInvoice.discount, editInvoice.deposit]);

  const validate = () => {
    if (
      editInvoice.username == undefined ||
      editInvoice.email == undefined ||
      editInvoice.billingAddress == undefined ||
      editInvoice.method.length === 0
    ) {
      toast.error("Please Fill all Fields!");
      return;
    }
    // if (
    //   !invoiceData ||
    //   !invoiceData?.dealData ||
    //   !invoiceData?.dealData?.productName ||
    //   !invoiceData?.dealData?.price ||
    //   !invoiceData?.dealData?.tax ||
    //   !invoiceData?.dealData?.quantity
    // ) {
    //   toast.error(
    //     "Product details are not available. Please try again by generating the invoice!"
    //   );
    //   navigate(-1);
    //   return;
    // }
  };

  const submitInvoice = async () => {
    if (editInvoice.dueDate == null) {
      toast.error("Please Select Duedate!");
      return;
    }
    validate();
    const vatamount =
      ((invoiceData?.productDetails?.values?.subTotal - editInvoice.discount) *
        invoiceData?.productDetails?.values?.VAT) /
      100;

    const productData = [
      {
        productName: invoiceData?.productDetails?.packageName,
        price: invoiceData?.productDetails?.values?.subTotal,
        tax: invoiceData?.productDetails?.values?.VAT,
        taxAmount: vatAmount,
        discount: editInvoice?.discount,
        deposit: editInvoice?.deposit,
        total: totalAmount,
        items: invoiceData?.productDetails?.itemsArray,
        elements: invoiceData?.productDetails?.elementArray,
        services: invoiceData?.productDetails?.services,
      },
    ];
    const bankData = {
      bank: editInvoice.bank,
      ifsc: editInvoice.ifsc,
      paymentAccount: editInvoice.paymentAccount,
      branch: editInvoice.branch,
      message: editInvoice.message,
      swifCode: editInvoice.swifCode,
      sortCode: editInvoice.sortCode,
      productDescription: editInvoice.productDescription,
    };
    const payload = {
      propId: invoiceData.propId,
      total: balanceDue,
      cust_name: editInvoice.username,
      cust_email: editInvoice.email,
      billingAddress: editInvoice.billingAddress,
      invoice_date: editInvoice.invoiceDate,
      due_date: editInvoice.dueDate,
      method: editInvoice.method,

      bankDetails: JSON.stringify(bankData),
      productDetails: JSON.stringify(productData),
      deposit: editInvoice?.deposit,
    };

    sessionStorage.setItem("invoiceDetails", JSON.stringify(payload));
    sessionStorage.setItem("packageDetails", JSON.stringify(packageDetails));

    try {
      setInvoiceLoading(true);
      await singleInvoiceGeneration(payload, {
        onSuccess: async (res) => {
          setInvoiceLoading(false);
          sessionStorage.setItem("invoiceNumber", JSON.stringify(res.data));
          const Invoice = JSON.parse(sessionStorage.getItem("invoiceNumber"));
          navigate(
            `/partnerfoodpage-invoice/${Invoice != null && Invoice?.invoiceId}`
          );
          setTimeout(() => {
            setEditInvoice({
              username: "",
              email: "",
              billingAddress: "",
              dueDate: null,
              discount: 0,
              deposit: 0,
              message: "",
              productDescription: "",
            });
          }, [1000]);
        },
        onFailed: (err) => {
          toast.error(err.errormessage);
          setInvoiceLoading(false);
        },
      });
    } finally {
      setInvoiceLoading(false);
    }
  };
  const handleOk = () => {
    setIsExport(false);
  };

  const handleCancel = () => {
    setIsExport(false);
  };

  return (
    <div className="container-fluid">
      <Toaster position="top-center" reverseOrder={false} />
      <Sidebar>
        <section id="content-wrapper" class="dashboard-content-wrapper">
          <div className="row">
            <div className="col-md-10">
              <h6 className="gen_in">Generate Invoice</h6>
            </div>
            <div className="col-md-2">
              {invoiceLoading ? (
                <button className="gen_btn" type="button" disabled>
                  Generating...
                </button>
              ) : (
                <>
                  <button
                    className="gen_btn"
                    type="button"
                    onClick={() => submitInvoice()}
                  >
                    Generate
                  </button>
                </>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col in_data">
              <small> Customer Name</small> <br />
              <div className="mt-1">
                <input
                  type="text"
                  className="p-1 invoice_input"
                  value={editInvoice.username}
                  name="username"
                  onChange={onChnageInfo}
                />
              </div>
            </div>
            <div className="col in_data">
              <small> Email</small> <br />
              <div className="mt-1">
                <input
                  type="email"
                  className="p-1 invoice_input"
                  value={editInvoice.email}
                  onChange={onChnageInfo}
                  name="email"
                />
              </div>
            </div>
            <div className="col in_data">
              <small> Payment Method</small> <br />
              <select
                name="method"
                className="form-control"
                value={editInvoice?.method}
                onChange={handleMethod}
              >
                <option value="stripe">Stripe</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <small className="">Billing Address</small>
              <br />
              <textarea
                type="text"
                className="p-1 invoice_teactarea"
                value={editInvoice.billingAddress}
                onChange={onChnageInfo}
                name="billingAddress"
              />
            </div>
            <div className="col-md-2 col-sm-6">
              <small>Invoice Date</small>
              <br />
              <input
                id="invoiceDate"
                className="form-control"
                type="date"
                value={editInvoice.invoiceDate}
                onChange={handleChangeData}
                name="invoiceDate"
              />
            </div>
            <div className="col-md-2 col-sm-6">
              <small>Due Date</small>
              <br />
              <input
                type="date"
                className="inv_dat"
                value={editInvoice.dueDate}
                name="dueDate"
                onChange={onChnageInfo}
                min={new Date().toISOString().split("T")[0]}
              />
            </div>
            {/* <div className="col in_data">
              <small> Subscription</small> <br />
              <select
                name="subscription"
                className="form-control"
                value={editInvoice?.subscription}
                onChange={handleSub}
              >
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </select>
            </div> */}
          </div>
          {/* <div className="row mt-3">
            <div className="col">
              <b>Invoice Number</b>
              <br />
              <div className="number_inv">INV00128</div>
            </div>
          </div> */}
          <div className="card mt-3 p-3">
            <div className="row ">
              <h6 className="sub_title">Invoice Details</h6>
              <div className="container">
                <p className="pack_name">
                  {invoiceData?.productDetails?.packageName ?? "N/A"} -{" "}
                  {invoiceData?.productDetails &&
                  invoiceData?.productDetails?.services
                    ? invoiceData?.productDetails?.services.length
                    : 0}{" "}
                  course menu
                </p>
                <table
                  className="table table-bordered table-light table-striped-columns table-hover"
                  // id="fp-table"
                >
                  <thead>
                    <th>Course</th>
                    <th>Total</th>
                  </thead>
                  <tbody>
                    {packageDetails &&
                      packageDetails?.services &&
                      Array.isArray(packageDetails?.services) &&
                      packageDetails?.services.length !== 0 &&
                      packageDetails?.services.map((item, index) => {
                        const foodList = item?.items;

                        // Calculate total value only for non-zero or non-empty items if showZeroValue is false
                        const totalValue =
                          foodList &&
                          foodList.reduce((acc, item) => {
                            const itemValue =
                              typeof item.value === "string"
                                ? parseFloat(item.value) || 0
                                : typeof item.value === "number"
                                ? item.value
                                : 0;
                            return acc + itemValue;
                          }, 0);

                        // Check if all items in foodList should be excluded based on showZeroValue
                        const shouldShowHead =
                          packageDetails.proposalInfo.showZeroValue ||
                          foodList.some((x) => x.value !== 0 && x.value !== "");

                        if (!shouldShowHead) {
                          return null; // Skip this course head if no valid items
                        }

                        return (
                          <tr className="position-relative" key={index}>
                            <td style={{ width: "82%" }}>
                              <div className="position-relative">
                                <p className="mt-2 mb-2 ps-2 text-capitalize">
                                  {item.courseHead ?? "N/A"}
                                </p>
                                <ul className="pb-1 pt-0 m-0">
                                  {foodList &&
                                    foodList.length !== 0 &&
                                    foodList.map((x, idx) => {
                                      // Skip items with value 0 or "" when showZeroValue is false
                                      if (
                                        !packageDetails.proposalInfo
                                          ?.showZeroValue &&
                                        (x.value === 0 || x.value === "")
                                      ) {
                                        return null;
                                      }
                                      return (
                                        <li
                                          style={{
                                            color: "#747474",
                                            paddingTop: "5px",
                                          }}
                                          key={idx}
                                        >
                                          {x.head ?? "N/A"} - £{x.value ?? 0}
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            </td>
                            <td>
                              <span className="text_btm_">
                                £ {totalValue ?? 0}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>

                  <tfoot>
                    <tr>
                      <td></td>
                      <td className="text-start">£ {servicesTotal ?? 0}</td>
                    </tr>
                  </tfoot>
                </table>
                <p className="pack_name mb-2">Extra Charges</p>
                <table className="table table-bordered table-light table-striped-columns table-hover">
                  <thead>
                    <th scope="col">Charges</th>
                    <th scope="col">Price</th>
                  </thead>
                  <tbody>
                    {packageDetails &&
                      packageDetails?.itemsArray &&
                      Array.isArray(packageDetails?.itemsArray) &&
                      packageDetails?.itemsArray.length !== 0 &&
                      packageDetails?.itemsArray.map((item, index) => {
                        const itemPrice =
                          typeof item.price === "string"
                            ? parseFloat(item.price) || 0
                            : typeof item.price === "number"
                            ? item.price
                            : 0;

                        if (
                          packageDetails?.proposalInfo?.showZerovalue == true ||
                          itemPrice !== 0 ||
                          itemPrice !== "0"
                        ) {
                          return (
                            <tr key={index}>
                              <td>
                                {item?.description == ""
                                  ? ""
                                  : item.description}
                              </td>
                              <td>
                                £ {item?.price == "" ? 0 : item?.price ?? 0}
                              </td>
                            </tr>
                          );
                        }
                        return null;
                      })}

                    {/* <tr>
                      <td></td>
                      <td>
                        <input
                      type="text"
                      className="discount"
                      value={editInvoice.price}
                      onChange={handlePriceChange}
                      name="price"
                    />
                        {invoiceData?.productDetails?.proposalInfo
                          ?.totalPackage ?? 0}
                      </td>
                    </tr> */}

                    {packageDetails &&
                      packageDetails?.elementArray &&
                      Array.isArray(packageDetails?.elementArray) &&
                      packageDetails?.elementArray.length !== 0 &&
                      packageDetails?.elementArray.map((item, index) => {
                        const elemName = item.item;
                        const price = item.price;
                        if (
                          elemName.length !== 0 &&
                          price !== 0 &&
                          price !== ""
                        ) {
                          return (
                            <tr key={index}>
                              <td>{item.item ?? "N/A"}</td>
                              <td>£ {item.price ?? 0}</td>
                            </tr>
                          );
                        }
                      })}
                    <tr>
                      <td>
                        <b>Total of Extra Charges</b>
                      </td>
                      <td>
                        <strong>£ {itemsTotal ?? 0}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-end">VAT(%)</td>
                      <td colSpan={3}>
                        <td>
                          {" "}
                          {invoiceData?.productDetails?.values?.VAT ?? "N/A"}
                        </td>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr
                      class="text-offset"
                      style={{ border: "none !important" }}
                    >
                      <td style={{ fontWeight: "700", color: "#395870" }}>
                        Sub Total
                      </td>
                      <td className="text-left">
                        <strong>
                          {" "}
                          £ {packageDetails?.values?.allTotal ?? 0}
                        </strong>
                      </td>
                    </tr>
                    <tr class="text-offset table-row">
                      <td>Discount amount</td>
                      <td className="text-left">
                        {" "}
                        <input
                          type="text"
                          className="discount"
                          value={editInvoice.discount}
                          onChange={handleDiscount}
                          name="discount"
                        />
                      </td>
                    </tr>
                    <tr
                      class="text-offset"
                      style={{ border: "none !important" }}
                    >
                      <td>VAT Amount</td>
                      <td className="text-left">£ {vatAmount}</td>
                    </tr>
                    <tr class="text-offset table-row">
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td className="text-left">
                        <strong>£ {totalAmount ?? 0}</strong>
                      </td>
                    </tr>
                    <tr class="text-offset table-row">
                      <td className="table-row mt-3">Deposit</td>
                      <td className="text-left table-row">
                        <input
                          type="text"
                          className="discount"
                          value={editInvoice.deposit}
                          onChange={handleDepositChange}
                          name="deposit"
                        />
                      </td>
                    </tr>{" "}
                    <tr class="text-offset">
                      <td>
                        <b>Balance Due</b>
                      </td>
                      <td className="text-left">£ {balanceDue ?? "N/A"}</td>
                    </tr>
                  </tfoot>
                </table>
                {/* <label>Package Description</label>
                <textarea
                  cols={10}
                  rows={3}
                  type="text"
                  className="p-2 mt-1 invoice_input"
                  value={editInvoice.productDescription}
                  name="productDescription"
                  maxLength="70"
                  onChange={onChangeDescription}
                /> */}
              </div>
            </div>
          </div>
          <div className="row mt-3">
            {/* <div className="col-md-4">
              <div className="card p-3">
                <div className="mt-3">
                  <b> Message on Invoice</b> <hr />
                </div>
                <p>
                  Payment Account :
                  <input
                    type="text"
                    className="p-1 mt-1 invoice_input"
                    value={editInvoice.paymentAccount ?? "N/A"}
                    name="paymentAccount"
                    onChange={onChnageInfo}
                  />
                </p>
                <p>
                  Bank Name :{" "}
                  <input
                    type="text"
                    className="p-1 mt-1 invoice_input"
                    value={editInvoice.bank}
                    name="bank"
                    onChange={onChnageInfo}
                  />
                </p>
                <p>
                  IFSC Code :{" "}
                  <input
                    type="text"
                    className="p-1 mt-1 invoice_input"
                    value={editInvoice.ifsc}
                    name="ifsc"
                    onChange={onChnageInfo}
                  />
                </p>
                <p>
                  Branch :{" "}
                  <input
                    type="text"
                    className="p-1 mt-1 invoice_input"
                    value={editInvoice.branch}
                    name="branch"
                    onChange={onChnageInfo}
                  />
                </p>
              </div>
            </div> */}

            <div className="col-md-4">
              <div className="col in_data">
                <label htmlFor="">Message</label>
                <div className="mt-1">
                  <textarea
                    className="message-inv p-2"
                    value={editInvoice.message}
                    name="message"
                    onChange={onChnageInfo}
                  />
                </div>
              </div>
              {/* <div className="col in_data">
                <label htmlFor="">Attach file</label>
                <div className="mt-1">
                
                  <input type="file" className="attach-inv"></input>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </Sidebar>
    </div>
  );
}

export default Invoicing;
