import React, { useContext, useEffect, useState } from "react";
import "../Style/Style.css";
import Logo from "../Assets/pakawaanlogoo.png";
import Pakwaan from "../Assets/pakawaanlogoo.png";
import * as Ri from "react-icons/ri";
import * as Ti from "react-icons/ti";
import * as Md from "react-icons/md";
import * as Hi from "react-icons/hi";
import * as Bs from "react-icons/bs";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import * as Bi from "react-icons/bi";
import * as Ai from "react-icons/ai";
import * as Gi from "react-icons/gi";
import * as Go from "react-icons/go";
import { PiBowlFood } from "react-icons/pi";
import * as Tb from "react-icons/tb";
import * as Fi from "react-icons/fi";
import * as Lu from "react-icons/lu";
import * as Rx from "react-icons/rx";
import * as Fa from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AppContext } from "../Context/AppContext";
import jwt_decode from "jwt-decode";
import { Fragment } from "react";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdArrowBackIosNew } from "react-icons/md";
import { GoSidebarCollapse } from "react-icons/go";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { RiArrowLeftDoubleFill } from "react-icons/ri";
import Hamburger from "hamburger-react";
import { IoMdClose } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";

function Sidebar({ children }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    deleteCookieData,
    previlages,
    setPrevilages,
    cookieData,
    allSellerEnquiries,
    loginData,
    authLoading,
  } = useContext(AppContext);

  const pathArray = [
    "/features",
    "/create-group",
    "/add-feature",
    "/createpackage",
    "/packages",
    "/subdetails",
    "/apk-management",
    "/products",
    "/createproduct",
    "/all-privileges",
  ];

  const naviagte = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    JSON.parse(localStorage.getItem("isSidebarOpen")) || false
  );
  const [openProfile, setOpenProfile] = useState(false);
  const [jobRole, setJobRole] = useState([]);
  const [role, setRole] = useState("");
  const [hamb, setHamb] = useState(false);
  const [activeTab, setActiveTab] = useState("dashboard");
  const [jobRoleId, setJobRoleId] = useState([]);
  const [toggleSettings, setToggleSettings] = useState(false);

  useEffect(() => {
    if (pathArray.includes(pathname)) {
      setToggleSettings(true);
    }
  }, []);

  //handle click outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      // if (openProfile && e.target.closest(".profilename") === null) {
      //   setOpenProfile(false);
      // }
    };
    if (openProfile) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openProfile]);

  const handleToggle = () => {
    setToggleSettings(!toggleSettings);
  };
  const handleSidebarToggle = () => {
    const newSidebarState = !isSidebarOpen;
    setIsSidebarOpen(newSidebarState);
    localStorage.setItem("isSidebarOpen", JSON.stringify(newSidebarState));
  };

  const handleSmallMenu = () => {
    setHamb(!hamb);
  };
  const profileToggle = () => {
    setOpenProfile(!openProfile);
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleLogout = async () => {
    await deleteCookieData({
      onLogoutSuccess: () => {
        toast.success("You have been logged out!");
        setPrevilages(null);
        window.localStorage.removeItem("loginResponse");
        navigate("/login");
      },
    });
  };

  useEffect(() => {
    if (!cookieData) return;
    const token = jwt_decode(cookieData);
    setJobRole(token?.jobRole);
    setRole(token?.role);
    token.jobRole.map((role) => {
      setJobRoleId(role.p_jbId);
    });
  }, [cookieData]);

  useEffect(() => {
    if (!previlages || previlages.length == 0) {
      const data = JSON.parse(localStorage.getItem("loginResponse"));

      if (!data) return;
      setPrevilages(data?.privilages);
    }
  }, [previlages]);
  const userEmail = sessionStorage.getItem("userEmail");
  const jobRoleData = sessionStorage.getItem("jobRoleData");
  const handleProfile = () => {
    navigate("/profile");
  };

  console.log(openProfile);

  return (
    <div>
      <div
        id="navbar-wrapper"
        className={
          isSidebarOpen ? "dashboard-navbar" : "dashboard-navbar toggled"
        }
      >
        <div className="row">
          <div className="flexed d-flex">
            <div className="one-box" id="logo_wrapper">
              <div onClick={handleSidebarToggle}>
                <i className="hamburger-icon">
                  {/* {isSidebarOpen ? (
                    <IoMdClose
                      size={23}
                      style={{ marginLeft: "7px", marginTop: "15px" }}
                    />
                  ) : (
                    <Hamburger size={20} />
                  )} */}

                  <GiHamburgerMenu size={20} />
                </i>
              </div>
              <img src={Pakwaan} alt="" className="logo p-3" />
              <span>
                <div className="navbar-header">
                  <a
                    href="#"
                    className="navbar-brand"
                    id="sidebar-toggle"
                    onClick={handleSidebarToggle}
                  >
                    {/* <i data-feather="menu">
                      {isSidebarOpen ? (
                        <Ri.RiArrowRightDoubleLine />
                      ) : (
                        <MdKeyboardDoubleArrowLeft />
                      )}
                    </i> */}
                  </a>
                </div>
              </span>
              {/* <div className="search-box">
                <input
                  className="search-input"
                  type="text"
                  placeholder="Search something.."
                />
                <button className="search-btn">
                  <i>
                    <Bi.BiSearch />
                  </i>
                </button>
              </div> */}
            </div>
            <div className="two-box">
              <div className="icons-dashbar">
                <i onClick={() => navigate("/")}>
                  <Ai.AiOutlineHome />
                </i>
                <i onClick={profileToggle} style={{ cursor: "pointer" }}>
                  <Bi.BiUser />
                </i>
                {openProfile === true && (
                  <>
                    <div className="profilename">
                      <div className="profileset">
                        <div className="profilesets">
                          <h6 className="pro-role">
                            {previlages != "all" ? jobRoleData : "Super Admin"}
                          </h6>
                          <h5 className="pro-name">{loginData?.email}</h5>
                        </div>
                      </div>
                      <hr className="m-0" />
                      <div className="text-center log d-flex">
                        {authLoading ? (
                          <button
                            type="button"
                            className="dropdown-item logout pb-0"
                            disabled
                          >
                            <i className="me-2 mt-3 log-sym">
                              <Ai.AiOutlineLogout />
                            </i>
                            Loading..
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="dropdown-item logout pb-0"
                            onClick={handleLogout}
                          >
                            <i className="me-2 mt-3 log-sym">
                              <Ai.AiOutlineLogout />
                            </i>
                            Logout
                          </button>
                        )}

                        {/* <button className="pro-btn" onClick={handleProfile}>
                          Profile
                        </button> */}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="listed-navbar">
          <div>
            <img src={Logo} alt="" className="small-logo" />
          </div>
          <div className="toggle-side text-center">
            <i onClick={handleSmallMenu}>
              <Gi.GiHamburgerMenu />
            </i>
          </div>
        </div>
        {/* <div className={
          isSidebarOpen ? "arrow_icon" : "arrow_icon toggled"
        } onClick={handleSidebarToggle}>
        <i className="toggle-arrow">
          {isSidebarOpen ? <MdOutlineDoubleArrow /> : <RiArrowLeftDoubleFill />}
        </i>
        </div> */}
      </div>
      {hamb && <div>hamb data</div>}
      <div id="wrapper" className={isSidebarOpen ? "toggled" : ""}>
        <aside id="sidebar-wrapper">
          <ul className="sidebar-nav">
            <li className={pathname == "/" ? "active" : ""}>
              <Link
                to="/"
                onClick={() => handleTabClick("dashboard")}
                className="nav-item"
              >
                <i data-feather="grid">
                  <Rx.RxDashboard />
                </i>
                <p className="para">Dashboard</p>
                {/* <p className="mob-label">Dashboard</p> */}
              </Link>
            </li>
            {/* Admin section */}
            {/* {role?.length != 0 && role == "superadmin" && (
              <li className={pathname == "/admin" ? "active" : ""}>
                <Link to="/admin" onClick={() => handleTabClick("dashboard")}>
                  <i data-feather="grid">
                    <Md.MdOutlineAdminPanelSettings />
                  </i>
                  <p className="para">Admins</p>
                </Link>
              </li>
            )} */}
            {/* Sales Starts  */}
            {jobRole != null &&
            (previlages == "all" || jobRole.includes("sales")) ? (
              <>
                <h6 className="main-head">Sales</h6>
                {/* {previlages != null &&
                (previlages.includes("/enquiry") || previlages === "all") ? (
                  <li className={pathname === "/enquiry" ? "active" : ""}>
                    <Link
                      to="/enquiry"
                      onClick={() => handleTabClick("dashboard")}
                    >
                      <i data-feather="grid">
                        <Md.MdOutlineMedicalInformation />
                      </i>
                      <p className="para">Subscriber Enquiry</p>
                    </Link>
                  </li>
                ) : (
                  ""
                )} */}
                {previlages != null &&
                (previlages.includes("/leads") || previlages === "all") ? (
                  <li className={pathname == "/leads" ? "active" : ""}>
                    <Link to="/leads">
                      <i data-feather="grid">
                        <Hi.HiUser />
                      </i>
                      <p className="para">Customers</p>
                    </Link>
                  </li>
                ) : (
                  ""
                )}

                {previlages != null &&
                (previlages.includes("/proposals") || previlages === "all") ? (
                  <li className={pathname == "/proposals" ? "active" : ""}>
                    <Link to="/proposals">
                      <i data-feather="grid">
                        <Hi.HiAdjustments />
                      </i>
                      <p className="para">Proposals</p>
                    </Link>
                  </li>
                ) : (
                  ""
                )}

                {/* {previlages != null &&
                (previlages.includes("/register-seller") ||
                  previlages === "all") ? (
                  <li
                    className={pathname == "/register-seller" ? "active" : ""}
                  >
                    <Link to="/register-seller">
                      <i data-feather="grid">
                        <Hi.HiUserGroup />
                      </i>
                      <p className="para">Merchants</p>
                    </Link>
                  </li>
                ) : (
                  ""
                )} */}

                {/* {((previlages != null &&
                  Array.isArray(previlages) &&
                  previlages.includes("/sales-executives")) ||
                  previlages === "all") && (
                  <li
                    className={pathname == "/sales-executives" ? "active" : ""}
                  >
                    <Link
                      to="/sales-executives"
                      onClick={() => handleTabClick("products")}
                    >
                      <i data-feather="book">
                        <Bs.BsPersonCheck />
                      </i>
                      <p className="para">Sales Executives</p>
                    </Link>
                  </li>
                )} */}
              </>
            ) : (
              ""
            )}

            {/* Package Management starts  */}
            {jobRole != null && (role == "superadmin" || role == "admin") ? (
              <>
                {(previlages != null && previlages?.length < 0) ||
                  (previlages == "all" && (
                    <h6 className="main-head">Payments/Invoice</h6>
                  ))}
                {previlages != null &&
                  (previlages.includes("/custom-invoice") ||
                    previlages === "all") && (
                    <li
                      className={pathname == "/custom-invoice" ? "active" : ""}
                    >
                      <Link
                        to="/custom-invoice"
                        onClick={() => handleTabClick("dashboard")}
                      >
                        <i data-feather="grid">
                          <LiaFileInvoiceSolid />
                        </i>
                        <p className="para">Invoices</p>
                      </Link>
                    </li>
                  )}
                {/* {previlages != null &&
                  (previlages.includes("/payments") ||
                    previlages === "all") && (
                    <li className={pathname == "/payments" ? "active" : ""}>
                      <Link
                        to="/payments"
                        onClick={() => handleTabClick("dashboard")}
                      >
                        <i data-feather="grid">
                          <Bs.BsCash />
                        </i>
                        <p className="para">Payment Details</p>
                      </Link>
                    </li>
                  )} */}

                {/* {previlages != null &&
                  (previlages.includes("/invoice") || previlages === "all") && (
                    <li className={pathname == "/invoice" ? "active" : ""}>
                      <Link
                        to="/invoice"
                        onClick={() => handleTabClick("dashboard")}
                      >
                        <i data-feather="grid">
                          <Tb.TbFileInvoice />
                        </i>
                        <p className="para">Invoice Details</p>
                      </Link>
                    </li>
                  )} */}
              </>
            ) : (
              ""
            )}

            {/* Sales and Support starts */}
            {/* {jobRole != null && (role == "superadmin" || role == "admin") ? (
              <>
                {previlages != null &&
                  (previlages.includes("/support") || previlages === "all") && (
                    <>
                      <h6 className="main-head">Sales and Support</h6>
                      <li className={pathname === "/support" ? "active" : ""}>
                        <Link
                          to="/support"
                          onClick={() => handleTabClick("dashboard")}
                        >
                          <i data-feather="grid">
                            <Ti.TiTicket />
                          </i>
                          <p className="para">Ticket Rising</p>
                        </Link>
                      </li>
                    </>
                  )}
              </>
            ) : (
              ""
            )} */}

            {jobRole != null && (role == "superadmin" || role == "admin") ? (
              <>
                {/* {previlages != null &&
                  (previlages.includes("/messages") ||
                    previlages === "all") && (
                    <li className={pathname == "/messages" ? "active" : ""}>
                      <Link
                        to="/messages"
                        onClick={() => handleTabClick("dashboard")}
                      >
                        <i data-feather="grid">
                          <Ri.RiBillLine />
                        </i>
                        <p className="para">Billing Alert Messages</p>
                      </Link>
                    </li>
                  )} */}

                {/* Reports Starts */}
                {/* {(previlages != null && previlages?.length < 0) ||
                  (previlages == "all" && (
                    <h6 className="main-head">Reports</h6>
                  ))} */}
                {/* {previlages != null &&
                  (previlages.includes("/salesreport") ||
                    previlages === "all") && (
                    <li className={pathname == "/salesreport" ? "active" : ""}>
                      <Link
                        to="/salesreport"
                        onClick={() => handleTabClick("dashboard")}
                      >
                        <i data-feather="grid">
                          <Go.GoGraph />
                        </i>
                        <p className="para">Monthly Report</p>
                      </Link>
                    </li>
                  )} */}
                {/* {previlages != null &&
                  (previlages.includes("/dayreport") ||
                    previlages === "all") && (
                    <li className={pathname == "/dayreport" ? "active" : ""}>
                      <Link
                        to="/dayreport"
                        onClick={() => handleTabClick("dashboard")}
                      >
                        <i data-feather="grid">
                          <Go.GoReport />
                        </i>
                        <p className="para">other Reports</p>
                      </Link>
                    </li>
                  )}
                {previlages != null &&
                  (previlages.includes("/usage") || previlages === "all") && (
                    <li className={pathname == "/usage" ? "active" : ""}>
                      <Link
                        to="/usage"
                        onClick={() => handleTabClick("dashboard")}
                      >
                        <i data-feather="grid">
                          <Md.MdDataUsage />
                        </i>
                        <p className="para">App Usage Report</p>
                      </Link>
                    </li>
                  )}
                {previlages != null &&
                  (previlages.includes("/ratings") || previlages === "all") && (
                    <li className={pathname == "/ratings" ? "active" : ""}>
                      <Link
                        to="/ratings"
                        onClick={() => handleTabClick("dashboard")}
                      >
                        <i data-feather="grid">
                          <Md.MdOutlineGeneratingTokens />
                        </i>
                        <p className="para">Ratings And Feedbacks</p>
                      </Link>
                    </li>
                  )} */}
                {/* Settings after meeting */}
                {(role == "superadmin" || role == "admin") && (
                  <Fragment>
                    <h6 className="main-head" onClick={handleToggle}>
                      More Settings
                      <i className="icon-set" onClick={handleToggle}>
                        {toggleSettings ? (
                          <Bi.BiSolidUpArrow />
                        ) : (
                          <Bi.BiSolidDownArrow />
                        )}
                      </i>
                    </h6>

                    <li
                      className={
                        pathname == "/settings" ? "active" : " settings"
                      }
                    >
                      <Link onClick={() => handleToggle()}>
                        <i data-feather="grid" onClick={handleToggle}>
                          <Md.MdOutlineSettings />
                        </i>
                        Settings
                      </Link>
                    </li>

                    {toggleSettings && (
                      <>
                        {/* {previlages &&
                          (previlages == "all" ||
                            previlages.includes("/features")) && (
                            <li
                              className={
                                pathname == "/features" ? "active" : ""
                              }
                            >
                              <Link
                                to="/features"
                                onClick={() => handleTabClick("dashboard")}
                              >
                                <i data-feather="grid">
                                  <Md.MdOutlineFeaturedVideo />
                                </i>
                                <p className="para">Features</p>
                              </Link>
                            </li>
                          )} */}
                        {/* {previlages &&
                          (previlages == "all" ||
                            previlages.includes("/feature-group")) && (
                            <li
                              className={
                                pathname == "/feature-group" ? "active" : ""
                              }
                            >
                              <Link
                                to="/feature-group"
                                onClick={() => handleTabClick("dashboard")}
                              >
                                <i data-feather="grid">
                                  <Lu.LuPackage2 />
                                </i>
                                <p className="para">Feature Group</p>
                              </Link>
                            </li>
                          )} */}

                        {/* <li
                          className={pathname == "/add-feature" ? "active" : ""}
                        >
                          <Link
                            to="/add-feature"
                            onClick={() => handleTabClick("dashboard")}
                          >
                            <i data-feather="grid">
                              <Md.MdOutlineCreateNewFolder />
                            </i>
                            <p className="para">Create Feature</p>
                          </Link>
                        </li> */}

                        {/* {previlages &&
                          (previlages == "all" ||
                            previlages.includes("/packages")) && (
                            <li
                              className={
                                pathname == "/packages" ? "active" : ""
                              }
                            >
                              <Link
                                to="/packages"
                                onClick={() => handleTabClick("dashboard")}
                              >
                                <i data-feather="grid">
                                  <Lu.LuPackage2 />
                                </i>
                                <p className="para">Packages</p>
                              </Link>
                            </li>
                          )} */}

                        {/* {previlages &&
                          (previlages == "all" ||
                            previlages.includes("/createpackage")) && (
                            <li
                              className={
                                pathname == "/createpackage" ? "active" : ""
                              }
                            >
                              <Link
                                to="/createpackage"
                                onClick={() => handleTabClick("products")}
                              >
                                <i data-feather="book">
                                  <Md.MdOutlineCreateNewFolder />
                                </i>
                                <p className="para">Create package</p>
                              </Link>
                            </li>
                          )} */}

                        {/* {previlages &&
                          (previlages == "all" ||
                            previlages.includes("/apk-management")) && (
                            <li
                              className={
                                pathname == "/apk-management" ? "active" : ""
                              }
                            >
                              <Link
                                to="/apk-management"
                                onClick={() => handleTabClick("dashboard")}
                              >
                                <i data-feather="grid">
                                  <Md.MdOutlineStore />
                                </i>
                                <p className="para">APK Management</p>
                              </Link>
                            </li>
                          )} */}

                        {/* Subscriptions Starts */}

                        {/* {previlages &&
                          (previlages == "all" ||
                            previlages.includes("/subdetails")) && (
                            <li
                              className={
                                pathname == "/subdetails" ? "active" : ""
                              }
                            >
                              <Link
                                to="/subdetails"
                                onClick={() => handleTabClick("dashboard")}
                              >
                                <i data-feather="grid">
                                  <Md.MdOutlineRememberMe />
                                </i>
                                <p className="para">Subscription Details</p>
                              </Link>
                            </li>
                          )} */}

                        {/* {previlages &&
                          (previlages == "all" ||
                            previlages.includes("/createproduct")) && (
                            <li
                              className={
                                pathname == "/createproduct" ? "active" : ""
                              }
                            >
                              <Link
                                to="/createproduct"
                                onClick={() => handleTabClick("products")}
                              >
                                <i data-feather="book">
                                  <Md.MdOutlineCreateNewFolder />
                                </i>
                                <p className="para">Create Product</p>
                              </Link>
                            </li>
                          )} */}

                        {/* {previlages &&
                          (previlages == "all" ||
                            previlages.includes("/products")) && (
                            <li
                              className={
                                pathname == "/products" ? "active" : ""
                              }
                            >
                              <Link
                                to="/products"
                                onClick={() => handleTabClick("dashboard")}
                              >
                                <i data-feather="grid">
                                  <Md.MdCategory />
                                </i>
                                <p className="para">Products</p>
                              </Link>
                            </li>
                          )} */}

                        {/* {previlages &&
                          (previlages == "all" ||
                            role == "superadmin" ||
                            previlages.includes("/products")) && (
                            <li
                              className={
                                pathname == "/contract-settings" ? "active" : ""
                              }
                            >
                              <Link
                                to="/contract-settings"
                                onClick={() => handleTabClick("dashboard")}
                              >
                                <i data-feather="grid">
                                  <Md.MdCategory />
                                </i>
                                <p className="para">Contract Signing</p>
                              </Link>
                            </li>
                          )} */}
                        {previlages &&
                          (previlages == "all" ||
                            previlages.includes("/features")) && (
                            <li
                              className={
                                pathname == "/admin/event-packages"
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                to="/admin/event-packages"
                                onClick={() => handleTabClick("dashboard")}
                              >
                                <i data-feather="grid">
                                  <PiBowlFood />
                                </i>
                                <p className="para">Meals</p>
                              </Link>
                            </li>
                          )}
                        {/* {previlages &&
                          (previlages == "all" ||
                            previlages.includes("/features")) && (
                            <li
                              className={
                                pathname == "/all-privileges" ? "active" : ""
                              }
                            >
                              <Link
                                to="/all-privileges"
                                onClick={() => handleTabClick("dashboard")}
                              >
                                <i data-feather="grid">
                                  <Fa.FaUserAstronaut />
                                </i>
                                <p className="para">Privilege Settings</p>
                              </Link>
                            </li>
                          )}
                        {role?.length != 0 && role == "superadmin" && (
                          <li className={pathname == "/admin" ? "active" : ""}>
                            <Link
                              to="/admin"
                              onClick={() => handleTabClick("dashboard")}
                            >
                              <i data-feather="grid">
                                <Md.MdOutlineAdminPanelSettings />
                              </i>
                              <p className="para">Admins</p>
                            </Link>
                          </li>
                        )} */}
                        {/* {role?.length != 0 && role == "superadmin" && (
                          <li
                            className={
                              pathname == "/template-creation" ? "active" : ""
                            }
                          >
                            <Link
                              to="/template-creation"
                              onClick={() =>
                                handleTabClick("template-creation")
                              }
                            >
                              <i data-feather="grid">
                                <Md.MdOutlineAdminPanelSettings />
                              </i>
                              <p className="para">Template Creation</p>
                            </Link>
                          </li>
                        )} */}
                      </>
                    )}
                  </Fragment>
                )}
              </>
            ) : (
              ""
            )}
          </ul>
        </aside>
        <section
          id="content-wrapper"
          className={
            isSidebarOpen
              ? "dashboard-content-wrapper"
              : "dashboard-content-wrapper toggled"
          }
        >
          {children}
        </section>
      </div>
    </div>
  );
}

export default Sidebar;
